const fetchBlockchainRecord = async (txid, pubkey, pubkeyURL) => {
  try {
    console.log("txid:", txid);
    console.log("pubkey:", pubkey);
    console.log("pubkeyURL:", pubkeyURL);

    let requestBody = { txid };

    if (pubkey) {
      requestBody.pubkey = pubkey;
    }

    if (pubkeyURL) {
      requestBody.pubkeyURL = pubkeyURL;
    }

    const response = await fetch(
      "https://serving.armilis.com/api/v0.3a/transactionverify",
      {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchBlockchainRecord:", error);
    return null;
  }
};

export default fetchBlockchainRecord;
