// React
import React from "react";

// Material UI Components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// Components
import MetadataField from "./MetadataField";

// metadataSchema
import { metadataSchema } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const SoggettoForm = ({ soggetto, index, soggettoFields, handleSoggettoChange, handleRemoveSoggetto, handleClickOpen }) => {
  const handleChange = (fieldKey, value) => {
    handleSoggettoChange(index, fieldKey, value);
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" color="gray" fontWeight="bold">
          {`Soggetto ${index + 1}`}
        </Typography>
        <Divider />
      </Grid>
      {soggettoFields.map((fieldKey) => (
        <MetadataField key={fieldKey} fieldKey={fieldKey} metadata={metadataSchema} documentProperties={soggetto} handleChange={handleChange} handleClickOpen={handleClickOpen} />
      ))}
      <Grid item container xs={12} justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={() => handleRemoveSoggetto(index)}>
          Rimuovi Soggetto
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoggettoForm;
