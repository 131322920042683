// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addSeenTag = async (tag, uuid, name, notes) => {
  try {
    const userRef = doc(db, "userdata", uuid);
    const seentagsRef = collection(userRef, "seentags");

    await setDoc(doc(seentagsRef, tag), {
      nickname: name || "",
      notes: notes || "",
      firstseen: unixTimestampInSeconds(),
    });
    console.log(`Tag ${tag} added to seen tags for user ${uuid}.`);
  } catch (error) {
    console.error(`Error in addSeenTag for tag ${tag} and user ${uuid}:`, error.message);
  }
};

export default addSeenTag;
