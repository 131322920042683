// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const unsetCanCreateDataboxFlag = async (keyID) => {
  try {
    const docRef = doc(db, "keysecrets", keyID);
    await updateDoc(docRef, {
      canCreateDatabox: false,
    });
    console.log(`Document with ID ${keyID} successfully updated.`);
  } catch (error) {
    console.error(`Error in unsetCanCreateDataboxFlag for keyID ${keyID}:`, error.message);
  }
};

export default unsetCanCreateDataboxFlag;
