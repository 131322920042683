export const initialDocumentProperties = {
  nomeDelDocumento: "",
  tipologiaDocumentale: "",
  impronta: "",
  algoritmo: "",
  identificativoDocumento: "",
  formatoDocumento: "",
  prodottoSoftwareNome: "",
  prodottoSoftwareVersione: "",
  prodottoSoftwareProduttore: "",
  versioneDelDocumento: "",
  modalitaDiFormazione: "",
  tipologiaDiFlusso: "",
  tipoRegistro: "",
  dataRegistrazione: "",
  numeroDocumento: "",
  codiceRegistro: "",
  soggetti: [
    {
      ruolo: "",
      tipoSoggetto: "",
      cognome: "",
      nome: "",
      codiceFiscale: "",
      partitaIva: "",
      indirizziDigitaliDiRiferimento: "",
      denominazioneOrganizzazione: "",
      denominazioneUfficio: "",
      denominazioneAmministrazioneCodiceIPA: "",
      denominazioneAmministrazioneAOOCodiceIPAAOO: "",
      denominazioneAmministrazioneUORCodiceIPAUOR: "",
      denominazioneAmministrazione: "",
      denominazioneSistema: "",
    },
  ],
  numeroAllegati: "",
  idDocAllegati: "",
  descrizioneAllegati: "",
  indiceDiClassificazione: "",
  descrizioneClassificazione: "",
  pianoDiClassificazione: "",
  riservato: "",
  verificaFirmaDigitale: "",
  verificaSigilloElettronico: "",
  verificaMarcaturaTemporale: "",
  verificaConformitaCopie: "",
  idAggregazione: "",
  idDocDocumentoPrimario: "",
  tipoModifica: "",
  soggettoAutoreDellaModifica: "",
  dataOraModifica: "",
  idDocVersionePrecedente: "",
  tempoDiConservazione: "",
  oggetto: "",
  paroleChiave: "",
  note: "",
};
