// React
import React, { useEffect, useState } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";

// GroupOperations
import postGroup from "GroupOperations/postGroup";

// A ---------------------------------------------------------------------- M

const GroupGenerator = ({ open, setOpen, handleSuccessful, handleError }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [groupname, setGroupname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleCreateGroup = async () => {
    setIsLoading(true);
    try {
      await postGroup(user.uid, groupname);
      console.log("Group created successfully");
      handleSuccessful();
    } catch (error) {
      console.error("Error in handleCreateGroup:", error.message);
      handleError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleReset = () => {
    setGroupname("");
    setOpen(false);
  };

  const groupForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth margin="dense" id="groupName" name="groupName" label={t("name")} required variant="outlined" value={groupname} onChange={(e) => setGroupname(e.target.value)} />
      </Grid>
    </Grid>
  );

  return isLoading ? (
    <LoadingDialog open={isLoading} />
  ) : isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
      data-testid="group-create-modal"
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_group")}
            </Typography>
          </Grid>
        </Grid>
        {groupForm}
        <Grid container mt="3%">
          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={handleCreateGroup} disabled={!groupname} sx={{ py: 1.5 }}>
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth data-testid="group-create-modal">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_group")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {groupForm}
        <Grid item container xs={12} mt="3%">
          <Grid item xs={12}>
            <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={handleCreateGroup} disabled={!groupname} sx={{ py: 1.5 }}>
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GroupGenerator;
