// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import ClientDetails from "ui-components/CustomerManagement/ClientDetails";
import ClientPolicies from "ui-components/CustomerManagement/ClientPolicies";
import EditClientPolicy from "ui-components/CustomerManagement/EditClientPolicy";

// Firebase
import { db } from "config/firebase";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const ClientSettings = () => {
  const { conservSostL1 } = UserAuth();
  const { customer } = useParams();
  const { t } = useTranslation();

  const [client, setClient] = useState();
  const [agreements, setAgreements] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState();
  const [openEditClientPolicy, setOpenEditClientPolicy] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      setIsLoading(true);
      try {
        const customerDocRef = doc(db, "clientsdata", customer);
        const customerDocSnap = await getDoc(customerDocRef);

        if (customerDocSnap.exists()) {
          const id = customerDocRef.id;
          const clientData = customerDocSnap.data();
          const agreementsRef = collection(customerDocRef, "agreements");
          const agreementsSnap = await getDocs(agreementsRef);

          const agreementsList = agreementsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setClient({ id, ...clientData });
          setAgreements(agreementsList);
        } else {
          console.error("Customer document not found");
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomerData();

    const clientsdataDocRef = doc(db, "clientsdata", customer);
    const agreementsCollectionRef = collection(clientsdataDocRef, "agreements");

    const modifyUnsubscribeQueryPolicies = onSnapshot(agreementsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          fetchCustomerData();
        }
      });
    });

    return () => {
      modifyUnsubscribeQueryPolicies();
    };
  }, [customer]);

  const clickEdit = (agreement) => {
    setSelectedAgreement(agreement);
    setOpenEditClientPolicy(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item container xs={12} spacing={3}>
      {client && (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom fontWeight="bold">
            {t("customer")}: {client.companyName || customer}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      {client && (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <ClientDetails client={client} />
            </Box>
          </Paper>
        </Grid>
      )}

      {client && agreements && (
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2}>
              <ClientPolicies client={client} agreements={agreements} clickEdit={clickEdit} />
            </Box>
          </Paper>
        </Grid>
      )}

      {client && selectedAgreement && <EditClientPolicy client={client} agreement={selectedAgreement} open={openEditClientPolicy} setOpen={setOpenEditClientPolicy} />}
    </Grid>
  );
};

export default ClientSettings;
