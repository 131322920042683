// React
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

// Material UI Date Pickers
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// Utils
import exportCSV from "utils/exportCSV";

// A ---------------------------------------------------------------------- M

const MpsExportCSV = ({ tag, open, handleClose, isTagGroupMember }) => {
  const { t } = useTranslation();

  const [magnitudes, setMagnitudes] = useState([]);
  const [selectedMagnitude, setSelectedMagnitude] = useState("");
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetch = async () => {
      const records = await fetchRecordsByType(tag, "mps", isTagGroupMember, startDate, endDate);
      const magnitudeRecords = records.reduce((acc, record) => {
        const { magnitude } = record.data;
        acc[magnitude] = [...(acc[magnitude] || []), record].sort((a, b) => a.data.timestamp - b.data.timestamp);
        return acc;
      }, {});

      if (Object.keys(magnitudeRecords).length !== 0) {
        setMagnitudes(Object.keys(magnitudeRecords));
        setSelectedMagnitude(Object.keys(magnitudeRecords)[0]);
      } else {
        setMagnitudes([]);
        setSelectedMagnitude("");
      }
    };

    fetch();
  }, [tag, startDate, endDate]);

  const handleChange = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const handleExportCSV = async () => {
    const records = await fetchRecordsByType(tag, "mps", isTagGroupMember, startDate, endDate);
    const csvArray = records.map((record) => {
      return {
        id: record.id,
        timestamp: record.data.timestamp,
        value: record.data.value,
        uom: record.data.uom,
      };
    });
    exportCSV(csvArray.reverse());
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle variant="h5" fontWeight="bold">
        {t("export")}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker label={t("start_date")} value={startDate} onChange={(date) => setStartDate(date)} />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker label={t("end_date")} value={endDate} onChange={(date) => setEndDate(date)} />
              </LocalizationProvider>
            </Grid>
            {magnitudes.length !== 0 && (
              <Grid item xs={12} sm={4}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                  <FormLabel>{t("select_parameter")}</FormLabel>
                  <RadioGroup value={selectedMagnitude} onChange={handleChange}>
                    {magnitudes.map((magnitude) => {
                      return <FormControlLabel key={magnitude} value={magnitude} control={<Radio />} label={magnitude} />;
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          {t("cancel")}
        </Button>
        <Button disabled={selectedMagnitude === ""} onClick={handleExportCSV} variant="contained">
          {t("export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MpsExportCSV;
