// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTagTypes = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const tagSnapshot = await getDoc(tagRef);

    if (!tagSnapshot.exists()) {
      return [];
    }

    return tagSnapshot.data()?.types || [];
  } catch (error) {
    console.error(`Error in getTagTypes for tagID ${tagID}:`, error.message);
    return [];
  }
};

export default getTagTypes;
