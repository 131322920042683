// Firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";

// RecordOperations
import fetchSignature from "RecordOperations/fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchCDNFileRecords = async (tagID, isTagGroupMember, filterCriteria) => {
  try {
    if (!isTagGroupMember) {
      console.error("Error in fetchCDNFileRecords: missing permissions");
      return null;
    }

    const tagRef = doc(db, "tagsdata", tagID);
    const signaturesRef = collection(tagRef, "signatures");

    let q = query(signaturesRef, where("type", "==", "CDNFile"));

    const snapshot = await getDocs(q);

    // Filter documents locally
    const filteredDocs = snapshot.docs.filter((doc) => {
      const data = doc.data();
      let matches = true;

      Object.keys(filterCriteria).forEach((key) => {
        if (filterCriteria[key]) {
          if ((key === "dataRegistrazioneStart" && data.dataRegistrazione < filterCriteria[key]) || (key === "dataRegistrazioneEnd" && data.dataRegistrazione > filterCriteria[key])) {
            matches = false;
          } else if (
            [
              "ruolo",
              "tipoSoggetto",
              "cognome",
              "nome",
              "codiceFiscale",
              "partitaIva",
              "denominazioneOrganizzazione",
              "denominazioneUfficio",
              "denominazioneAmministrazioneCodiceIPA",
              "denominazioneAmministrazioneAOOCodiceIPAAOO",
              "denominazioneAmministrazioneUORCodiceIPAUOR",
              "denominazioneAmministrazione",
              "denominazioneSistema",
            ].includes(key)
          ) {
            const soggetti = data.soggetti || [];
            const soggettoMatches = soggetti.find((soggetto) => {
              return soggetto[key] === filterCriteria[key];
            });
            if (!soggettoMatches) {
              matches = false;
            }
          } else if (key !== "dataRegistrazioneStart" && key !== "dataRegistrazioneEnd" && data[key] !== filterCriteria[key]) {
            matches = false;
          }
        }
      });

      return matches;
    });

    const signaturePromises = filteredDocs.map(async (sig) => {
      const { txid, public: isPublic = false } = sig.data();
      const signature = await fetchSignature(txid, isPublic);
      return signature;
    });

    const signatures = await Promise.all(signaturePromises);
    return signatures.filter((obj) => obj !== undefined);
  } catch (error) {
    console.error("Error in fetchCDNFileRecords:", error);
    return null;
  }
};

export default fetchCDNFileRecords;
