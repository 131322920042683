// Firebase
import { db } from "config/firebase";
import { collection, query, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

// Function to fetch events for a specific Databox
const fetchDataboxEvents = async (databoxID) => {
  try {
    const eventsRef = collection(db, "tagsdata", databoxID, "events");
    const q = query(eventsRef);
    const querySnapshot = await getDocs(q);

    const events = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return events;
  } catch (error) {
    console.error("Error fetching events:", error);
    return [];
  }
};

export default fetchDataboxEvents;
