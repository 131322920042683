// React
import React, { useState, useMemo, useCallback } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import NoteIcon from "@mui/icons-material/Note";
import SettingsIcon from "@mui/icons-material/Settings";

// A ---------------------------------------------------------------------- M

const CertificationMenu = ({ tag, openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openConfigurationGenDrawer }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCertify = useCallback(
    (type) => {
      handleClose();
      setSpeedDialOpen(false);
      const actionMap = {
        PDF: openDocGenDrawer,
        Image: openImageGenDrawer,
        Note: openInfoGenDrawer,
        Configuration: openConfigurationGenDrawer,
      };
      const action = actionMap[type];
      if (action) action();
    },
    [handleClose, openDocGenDrawer, openImageGenDrawer, openInfoGenDrawer, openConfigurationGenDrawer]
  );

  const handleSpeedDialOpen = () => {
    setSpeedDialOpen(true);
  };

  const handleSpeedDialClose = () => {
    setSpeedDialOpen(false);
  };

  const menuItems = useMemo(() => {
    const items = [
      { label: t("certify_document"), type: "PDF", icon: <DescriptionIcon /> },
      { label: t("certify_image"), type: "Image", icon: <ImageIcon /> },
      { label: t("certify_note"), type: "Note", icon: <NoteIcon /> },
    ];

    if (tag?.targets?.length) {
      items.push({ label: t("certify_configuration"), type: "Configuration", icon: <SettingsIcon /> });
    }

    return items;
  }, [t, tag]);

  return (
    <>
      {isMobile ? (
        <>
          {speedDialOpen && (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1,
              }}
              onClick={handleSpeedDialClose}
            />
          )}
          <SpeedDial
            ariaLabel="certification-options"
            sx={{ position: "fixed", bottom: 120, right: 16 }}
            icon={<AddIcon />}
            onOpen={handleSpeedDialOpen}
            onClose={handleSpeedDialClose}
            open={speedDialOpen}
            data-testid="create-certification-button"
          >
            {menuItems.map((item) => (
              <SpeedDialAction
                key={item.type}
                icon={item.icon}
                tooltipTitle={
                  <Box minWidth="120px">
                    <Typography variant="body1">{item.label}</Typography>
                  </Box>
                }
                tooltipOpen
                onClick={() => handleCertify(item.type)}
                sx={{
                  marginBottom: 2,
                }}
              />
            ))}
          </SpeedDial>
        </>
      ) : (
        <Button
          aria-controls="certification-menu"
          aria-haspopup="true"
          aria-label={t("new_certification")}
          variant="contained"
          fullWidth
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={{ py: 1.5 }}
          data-testid="create-certification-button"
        >
          {t("new_certification")}
        </Button>
      )}
      <Menu
        id="certification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : undefined,
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.type} onClick={() => handleCertify(item.type)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CertificationMenu;
