// Firebase
import { db } from "config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeViewGroupReadOnly = async (tagID, groupID) => {
  try {
    const viewgroupRef = doc(db, "tagsdata", tagID, "viewgroups_readonly", groupID);
    await deleteDoc(viewgroupRef);
    return { code: 0 };
  } catch (error) {
    console.error(`Error in removeViewGroupReadOnly for tagID ${tagID} and groupID ${groupID}:`, error.message);
    return { code: 1 };
  }
};

export default removeViewGroupReadOnly;
