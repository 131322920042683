// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const AccountCard = () => {
  const { user, clientData } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box data-testid="account-information-card">
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {t("account_information")}
      </Typography>
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
              <b>{t("user_id")}</b>: {user.uid || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
              <b>{t("email")}</b>: {user.email || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
              <b>{t("customer_name")}</b>: {clientData ? clientData.companyName || clientData.id : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default AccountCard;
