// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import GroupToolbar from "ui-components/GroupManagement/GroupToolbar";
import MembersTable from "ui-components/GroupManagement/MembersTable";
import SharedTagsTable from "ui-components/GroupManagement/SharedTagsTable";
import AddMember from "ui-components/GroupManagement/AddMember";
import { MemberAddedSuccessfulSnackbar, MemberAddedErrorSnackbar, MemberRemovedSuccessfulSnackbar, MemberRemovedErrorSnackbar } from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where, collectionGroup } from "firebase/firestore";

// GroupOperations
import getGroup from "GroupOperations/getGroup";
import getGroupMembers from "GroupOperations/getGroupMembers";

// TagOperations
import getTagsByViewGroup from "TagOperations/getTagsByViewGroup";
import getTagsByViewGroupReadOnly from "TagOperations/getTagsByViewGroupReadOnly";

// A ---------------------------------------------------------------------- M

const GroupSettings = () => {
  const groupID = useParams().group;
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  const [group, setGroup] = useState();
  const [members, setMembers] = useState([]);
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMemberAddedSuccessful, setOpenMemberAddedSuccessful] = useState(false);
  const [openMemberAddedError, setOpenMemberAddedError] = useState(false);
  const [openMemberRemovedSuccessful, setOpenMemberRemovedSuccessful] = useState(false);
  const [openMemberRemovedError, setOpenMemberRemovedError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGroupMembers = async () => {
      const group = await getGroup(groupID);
      const members = await getGroupMembers(groupID);
      setGroup(group);
      setMembers(members);
    };

    const fetchDataboxes = async () => {
      const group = await getGroup(groupID);

      const readwriteTags = await getTagsByViewGroup(groupID);
      const readonlyTags = await getTagsByViewGroupReadOnly(groupID);

      const markedReadwriteTags = readwriteTags.map((tag) => ({
        ...tag,
        source: "readwrite",
      }));

      const markedReadonlyTags = readonlyTags.map((tag) => ({
        ...tag,
        source: "readonly",
      }));

      const combinedTags = [...markedReadwriteTags, ...markedReadonlyTags];

      console.log(combinedTags);
      setGroup(group);
      setTags(combinedTags);
    };

    const fetchData = async () => {
      await fetchGroupMembers();
      await fetchDataboxes();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const groupsdataDocRef = doc(db, "groupsdata", groupID);
    const usersCollectionRef = collection(groupsdataDocRef, "users");

    const newAdditionsQueryMembers = query(usersCollectionRef, where("added_on", ">=", currentTimestampInSeconds.toString()));

    const newAdditionsUnsubscribeMembers = onSnapshot(newAdditionsQueryMembers, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchGroupMembers();
        }
      });
    });

    const deletionUnsubscribeMembers = onSnapshot(usersCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchGroupMembers();
        }
      });
    });

    const newAdditionsQueryTags = conservSostL1
      ? query(collectionGroup(db, "viewgroups_readonly"), where("group_id", "==", groupID), where("added_on", ">", currentTimestampInSeconds.toString()))
      : query(collectionGroup(db, "viewgroups"), where("group_id", "==", groupID), where("added_on", ">", currentTimestampInSeconds.toString()));

    const newAdditionsUnsubscribeTags = onSnapshot(newAdditionsQueryTags, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetch();
        }
      });
    });

    return () => {
      newAdditionsUnsubscribeMembers();
      deletionUnsubscribeMembers();
      newAdditionsUnsubscribeTags();
    };
  }, [groupID]);

  const handleOpenMemberAddedSuccessful = () => {
    setOpenMemberAddedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberAddedError = () => {
    setOpenMemberAddedError(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberRemovedSuccessful = () => {
    setOpenMemberRemovedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenMemberRemovedError = () => {
    setOpenMemberRemovedError(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item container xs={12} spacing={5}>
      {group && (
        <>
          <Grid item xs={12}>
            <GroupToolbar group={group} />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ borderBottomWidth: 1, bgcolor: "black" }} />
          </Grid>

          <Grid item container xs={12} spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                {t("settings")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={5}>
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box p={2}>
                    <MembersTable group={group} members={members} setOpen={setOpen} handleSuccessfulRemoved={handleOpenMemberRemovedSuccessful} handleErrorRemoved={handleOpenMemberRemovedError} />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box p={2}>
                    <SharedTagsTable tags={tags} />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <AddMember group={group} open={open} setOpen={setOpen} handleSuccessfulAdded={handleOpenMemberAddedSuccessful} handleErrorAdded={handleOpenMemberAddedError} />

          <MemberAddedSuccessfulSnackbar open={openMemberAddedSuccessful} setOpen={setOpenMemberAddedSuccessful} />

          <MemberAddedErrorSnackbar open={openMemberAddedError} setOpen={setOpenMemberAddedError} />

          <MemberRemovedSuccessfulSnackbar open={openMemberRemovedSuccessful} setOpen={setOpenMemberRemovedSuccessful} />

          <MemberRemovedErrorSnackbar open={openMemberRemovedError} setOpen={setOpenMemberRemovedError} />
        </>
      )}
    </Grid>
  );
};

export default GroupSettings;
