// Firebase
import { db } from "config/firebase";
import { collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getClients = async () => {
  try {
    const clientsCollection = collection(db, "clientsdata");
    const clientsSnapshot = await getDocs(clientsCollection);

    return clientsSnapshot.docs
      .filter((doc) => doc.id !== "defaultClient")
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  } catch (error) {
    console.error("Error fetching clients:", error.message);
    return [];
  }
};

export default getClients;
