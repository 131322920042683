import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addPubKeyUrl = async (uid, pubKeyURL) => {
  try {
    const userRef = doc(db, "usercontacts", uid);

    await updateDoc(userRef, {
      pubkeyurl: pubKeyURL,
    });
  } catch (error) {
    console.error("Error in addPubKeyUrl:", error.message);
  }
};

export default addPubKeyUrl;
