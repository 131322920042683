// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// Material UI Components
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// Material UI Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

// A ---------------------------------------------------------------------- M

const AuthorizedKeysNode = ({ data }) => {
  const { conservSostL1 } = UserAuth();

  return (
    <Card
      variant="outlined"
      sx={{
        width: "800px",
        p: 3,
        borderRadius: "8px", // More rounded for modern feel
        backgroundColor: "rgba(38, 50, 56, 0.9)", // Slightly darker gray
        backgroundImage: "linear-gradient(135deg, #263238 0%, #37474f 100%)",
        border: "1px solid rgba(255, 255, 255, 0.1)", // Subtle white border
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)", // Deeper shadow for immersion
        color: "#ffffff", // Light text for contrast
        overflow: "hidden", // Prevent content overflow
      }}
    >
      {/* Title */}
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        fontWeight="bold"
        sx={{
          color: "#ffffff",
          borderBottom: "2px solid #4caf50", // Green accent
          pb: 1,
          letterSpacing: "1px",
        }}
      >
        Authorized Sources
      </Typography>

      {/* Summary Box */}
      <Box
        mb={2}
        textAlign="center"
        sx={{
          backgroundColor: "rgba(76, 175, 80, 0.15)", // Faint green for contrast
          borderRadius: 2,
          py: 1,
          boxShadow: "inset 0px 1px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          Tot. N. {!conservSostL1 ? "Certifications" : "Documents"}: {data.totalNumberOfCertificationsKeys}
        </Typography>
      </Box>

      {/* Table Section */}
      <Table size="small" sx={{ backgroundColor: "transparent" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5", // Light gray
                  letterSpacing: "0.5px",
                }}
              >
                Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Count
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Status
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.authorizedKeysArray.map((key, index) => (
            <TableRow
              key={index}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.1)", // Highlight on hover
                  cursor: "pointer",
                },
                backgroundColor: key.key_number_of_certifications > 0 ? "#263238" : "#37474f", // Alternating row colors
              }}
            >
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {key.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {key.key_number_of_certifications}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={key.key_number_of_certifications > 0 ? "Certification Active" : "No Certifications"} arrow>
                  {key.key_number_of_certifications > 0 ? (
                    <CheckCircleOutlineIcon
                      sx={{
                        color: "#4caf50",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <CancelIcon
                      sx={{
                        color: "#f44336",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* ReactFlow Handle */}
      <Handle
        type="source"
        position={Position.Right}
        id="keys-source"
        style={{
          background: "#4caf50", // Green to match theme
          border: "2px solid #ffffff",
          width: "12px",
          height: "12px",
        }}
      />
    </Card>
  );
};

export default AuthorizedKeysNode;
