// React
import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Material UI Icons
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineIcon from "@mui/icons-material/Timeline";
import NoteIcon from "@mui/icons-material/Note";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import SensorsIcon from "@mui/icons-material/Sensors";
import CustomIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import CertificationMenu from "ui-components/CertificationManagement/CertificationMenu";
import AggregatedViewer from "ui-components/DataTypeManagement/History/AggregatedViewer";
import DocViewer from "ui-components/DataTypeManagement/Doc/DocViewer";
import ImageViewer from "ui-components/DataTypeManagement/Image/ImageViewer";
import InfoViewer from "ui-components/DataTypeManagement/Info/InfoViewer";
import ConfigViewer from "ui-components/DataTypeManagement/Config/ConfigViewer";
import MpsViewer from "ui-components/DataTypeManagement/Mps/MpsViewer";
import SensorDataAggregatedViewer from "ui-components/DataTypeManagement/SensorDataAggregated/SensorDataAggregatedViewer";
import LikeSirtiViewer from "ui-components/DataTypeManagement/Custom/LikeSirti/LikeSirtiViewer";
import CDNFileViewer from "ui-components/DataTypeManagement/CDNFile/CDNFileViewer";
import DocGenerator from "ui-components/DataTypeManagement/Doc/DocGenerator";
import ImageGenerator from "ui-components/DataTypeManagement/Image/ImageGenerator";
import InfoGenerator from "ui-components/DataTypeManagement/Info/InfoGenerator";
import ConfigGenerator from "ui-components/DataTypeManagement/Config/ConfigGenerator";
import CDNFileGenerator from "ui-components/DataTypeManagement/CDNFile/CDNFileGenerator";
import { CertificationSuccessful, CertificationError, DataBoxFound } from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, onSnapshot } from "firebase/firestore";

// TagOperations
import getTag from "TagOperations/getTag";
import getTagOwner from "TagOperations/getTagOwner";
import getTagTypes from "TagOperations/getTagTypes";
import getTags from "TagOperations/getTags";
import checkIfUserIsFollowingTag from "TagOperations/checkIfUserIsFollowingTag";
import addFollower from "TagOperations/addFollower";
import removeFollower from "TagOperations/removeFollower";

// UserOperations
import isUserAuthorizedForDataBox from "UserOperations/isUserAuthorizedForDataBox";
import isUserAuthorizedForDataBoxReadOnly from "UserOperations/isUserAuthorizedForDataBoxReadOnly";
import getUserContacts from "UserOperations/getUserContacts";
import fetchSeenTags from "UserOperations/fetchSeenTags";

// A ---------------------------------------------------------------------- M

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
};

const Tag = () => {
  const { user, conservSostL1, canSign, seedflow } = UserAuth();
  const { tag } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [currentTag, setCurrentTag] = useState({});
  const [request, setRequest] = useState();
  const [types, setTypes] = useState([]);
  const [seentag, setSeentag] = useState();
  const [tagExist, setTagExist] = useState(false);
  const [tagNotExist, setTagNotExist] = useState(false);
  const [tagConf, setTagConf] = useState(false);
  const [openDocGen, setOpenDocGen] = useState(false);
  const [openImageGen, setOpenImageGen] = useState(false);
  const [openInfoGen, setOpenInfoGen] = useState(false);
  const [openUploadGen, setOpenUploadGen] = useState(false);
  const [openConfigurationGen, setOpenConfigurationGen] = useState(false);
  const [openCertificationSuccessful, setOpenCertificationSuccessful] = useState(false);
  const [openCertificationError, setOpenCertificationError] = useState(false);
  const [isTagGroupMember, setIsTagGroupMember] = useState(false);
  const [isTagGroupMemberReadOnly, setIsTagGroupMemberReadOnly] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (tagNotExist) {
        handleTagNotExist();
      } else if (tagConf) {
        handleCloseTagConf();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [tagNotExist, tagConf]);

  useEffect(() => {
    const fetchTagInfo = async () => {
      const isTagGroupMember = await isUserAuthorizedForDataBox(tag, user.uid);
      const isTagGroupMemberReadOnly = await isUserAuthorizedForDataBoxReadOnly(tag, user.uid);
      const currentTag = await getTag(tag);
      setIsTagGroupMember(isTagGroupMember);
      setIsTagGroupMemberReadOnly(isTagGroupMemberReadOnly);
      setCurrentTag(currentTag);
    };

    const checkIsFollowing = async () => {
      const following = await checkIfUserIsFollowingTag(user.uid, tag);
      setIsFollowing(following);
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const tagFlow = async () => {
      const tagIDs = await getTags();

      if (tagIDs.includes(tag)) {
        const seentags = await fetchSeenTags(user.uid);
        const seentag = seentags.find((tagObj) => tagObj.id === tag);

        if (seentag) {
          const tagData = await getTag(tag);
          const tagName = tagData.name;
          const tagOwner = await getTagOwner(tag);
          const tagOwnerData = await getUserContacts(tagOwner);
          const tagOwnerEmail = tagOwnerData.email;
          const updatedSeentag = {
            ...seentag,
            name: tagName,
            tagOwner: tagOwner,
            tagOwnerEmail: tagOwnerEmail,
          };

          if (conservSostL1 && tagData.tipologiaDocumentale) {
            updatedSeentag.tipologiaDocumentale = tagData.tipologiaDocumentale;
          }

          setSeentag(updatedSeentag);
          setTagExist(true);

          const tagsdataDocRef = doc(db, "tagsdata", tag);
          const unsubscribeTypesModified = onSnapshot(tagsdataDocRef, (doc) => {
            getTypes();
          });

          return () => {
            unsubscribeTypesModified();
          };
        } else {
          handleTagConfDrawer();
        }
      } else {
        handleTagNotExistDrawer();
      }
    };

    const fetchData = async () => {
      await fetchTagInfo();
      await checkIsFollowing();
      await tagFlow();
      setIsLoading(false);
    };

    fetchData();
  }, [tag]);

  const openDocGenDrawer = () => {
    setOpenDocGen(true);
    window.history.pushState(null, "");
  };

  const openImageGenDrawer = () => {
    setOpenImageGen(true);
    window.history.pushState(null, "");
  };

  const openInfoGenDrawer = () => {
    setOpenInfoGen(true);
    window.history.pushState(null, "");
  };

  const openUploadGenDrawer = () => {
    setOpenUploadGen(true);
    window.history.pushState(null, "");
  };

  const openConfigurationGenDrawer = () => {
    setOpenConfigurationGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationSuccessful = () => {
    setOpenCertificationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenCertificationError = () => {
    setOpenCertificationError(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExistDrawer = () => {
    setTagNotExist(true);
    window.history.pushState(null, "");
  };

  const handleTagConfDrawer = () => {
    setTagConf(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExist = () => {
    setTagNotExist(false);
    navigate("/");
  };

  const handleCloseTagConf = () => {
    setTagConf(false);
    navigate("/");
  };

  const handleFollowToggle = async () => {
    if (isFollowing) {
      await removeFollower(user.uid, tag);
    } else {
      await addFollower(user.uid, tag);
    }
    setIsFollowing(!isFollowing);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleCertify = (request) => {
    setRequest(request);
    if (request.type === "info") {
      openInfoGenDrawer();
    } else if (request.type === "img") {
      openImageGenDrawer();
    } else if (request.type === "doc") {
      openDocGenDrawer();
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={tagNotExist}
          sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Box sx={{ p: "6%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SearchOffOutlinedIcon fontSize="large" sx={{ color: "red" }} />
            </Box>
            <Typography variant="h5" color="red" align="center" gutterBottom>
              Not Found
            </Typography>
            <Typography variant="body1" color="red" align="center" gutterBottom>
              {conservSostL1 ? t("archive") : t("databox")}: <b>{tag}</b> does not exist.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
              <Button variant="contained" onClick={handleTagNotExist}>
                {t("close")}
              </Button>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Snackbar open={tagNotExist} autoHideDuration={3000} onClose={handleTagNotExist} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{ mt: { xs: "20%", md: "5%" } }}>
          <Alert onClose={handleTagNotExist} severity="error" sx={{ width: "100%" }}>
            {conservSostL1 ? t("archive") : t("databox")}: <b>{tag}</b> does not exist.
          </Alert>
        </Snackbar>
      )}

      {currentTag?.name && <DataBoxFound tag={currentTag} open={tagConf} setOpen={setTagConf} handleClose={handleCloseTagConf} />}

      {tagExist && (
        <>
          <Grid item container xs={12} spacing={5}>
            {(isTagGroupMember || isTagGroupMemberReadOnly) && seentag && (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}

            <Grid item container xs={12} spacing={2}>
              {!conservSostL1 && (
                <>
                  <Grid item container alignItems="center">
                    <Grid item xs={12} sm={10}>
                      <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold">
                        {t("certifications")}
                      </Typography>
                    </Grid>
                    {isTagGroupMember && canSign && seedflow && currentTag && (
                      <Grid item container xs={12} sm={2} justifyContent="flex-end">
                        <CertificationMenu
                          tag={currentTag}
                          openDocGenDrawer={openDocGenDrawer}
                          openImageGenDrawer={openImageGenDrawer}
                          openInfoGenDrawer={openInfoGenDrawer}
                          openConfigurationGenDrawer={openConfigurationGenDrawer}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              )}

              <Grid item container xs={12} spacing={3}>
                {types.includes("sensor_data_aggregated") && currentTag && (
                  <Grid item xs={12}>
                    <Box p={2}>
                      <SensorDataAggregatedViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />
                    </Box>
                  </Grid>
                )}

                {(types.includes("cfg") || types.includes("mps")) && currentTag && (
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginBottom: 1,
                        backgroundColor: "grey.100",
                        boxShadow: 1,
                      }}
                    >
                      <Tabs value={value1} onChange={handleChange1} textColor="primary" indicatorColor="primary" sx={{ "& .MuiTab-root": { fontWeight: "bold" } }}>
                        {["cfg", "mps"]
                          .filter((type) => types.includes(type))
                          .sort()
                          .map((type) => (
                            <Tab
                              key={type}
                              icon={type === "cfg" ? <SettingsIcon fontSize="large" /> : <TimelineIcon fontSize="large" />}
                              label={
                                isMobile ? null : (
                                  <Typography variant="body1" color="primary">
                                    {type === "cfg" ? t("configurations") : t("stream")}
                                  </Typography>
                                )
                              }
                            />
                          ))}
                      </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                      {["cfg", "mps"]
                        .filter((type) => types.includes(type))
                        .sort()
                        .map((type, index) => (
                          <TabPanel key={type} value={value1} index={index}>
                            {type === "cfg" ? <ConfigViewer tag={currentTag} isTagGroupMember={isTagGroupMember} /> : <MpsViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}

                {(types.includes("doc") || types.includes("img") || types.includes("info") || types.includes("like_sirti") || types.includes("source_telemetrics")) && currentTag && (
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="flex-end"
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginBottom: 1,
                        backgroundColor: "grey.100",
                        boxShadow: 1,
                      }}
                    >
                      <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" sx={{ "& .MuiTab-root": { fontWeight: "bold" } }}>
                        {types.includes("doc") || types.includes("img") || types.includes("info") || types.includes("like_sirti") || types.includes("source_telemetrics") ? (
                          <Tab
                            icon={<AccessTimeIcon fontSize="large" />}
                            label={
                              isMobile ? null : (
                                <Typography variant="body1" color="primary">
                                  {t("history")}
                                </Typography>
                              )
                            }
                          />
                        ) : null}
                        {["doc", "img", "info", "like_sirti", "source_telemetrics"]
                          .filter((type) => types.includes(type))
                          .sort((a, b) => {
                            const order = ["doc", "img", "info", "like_sirti", "source_telemetrics"];
                            return order.indexOf(a) - order.indexOf(b);
                          })
                          .map((type) => {
                            const icon = {
                              doc: <DescriptionIcon fontSize="large" />,
                              img: <ImageIcon fontSize="large" />,
                              info: <NoteIcon fontSize="large" />,
                              like_sirti: <CustomIcon fontSize="large" />,
                              source_telemetrics: <SensorsIcon fontSize="large" />,
                            }[type];

                            const label = {
                              info: t("notes"),
                              doc: t("documents"),
                              img: t("images"),
                              like_sirti: "like_sirti",
                              source_telemetrics: "source_telemetrics",
                            }[type];
                            return (
                              <Tab
                                key={type}
                                icon={icon}
                                label={
                                  isMobile ? null : (
                                    <Typography variant="body1" color="primary">
                                      {label}
                                    </Typography>
                                  )
                                }
                              />
                            );
                          })}
                      </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <AggregatedViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />
                      </TabPanel>
                      {["doc", "img", "info", "like_sirti", "source_telemetrics"]
                        .filter((type) => types.includes(type))
                        .sort((a, b) => {
                          const order = ["doc", "img", "info", "like_sirti", "source_telemetrics"];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .map((type, index) => (
                          <TabPanel key={type} value={value} index={index + 1}>
                            {type === "doc" && <DocViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                            {type === "img" && <ImageViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                            {type === "info" && <InfoViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                            {type === "like_sirti" && <LikeSirtiViewer tag={currentTag} isTagGroupMember={isTagGroupMember} />}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}

                {conservSostL1 && (isTagGroupMember || isTagGroupMemberReadOnly) && currentTag && (
                  <Grid item xs={12}>
                    <CDNFileViewer
                      tag={currentTag}
                      isTagGroupMember={isTagGroupMember || isTagGroupMemberReadOnly}
                      isTagGroupMemberReadWrite={isTagGroupMember}
                      isTagGroupMemberReadOnly={isTagGroupMemberReadOnly}
                      openUploadGenDrawer={openUploadGenDrawer}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <DocGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openDocGen}
            setOpen={setOpenDocGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <ImageGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openImageGen}
            setOpen={setOpenImageGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          <InfoGenerator
            tag={tag}
            types={types}
            setTypes={setTypes}
            open={openInfoGen}
            setOpen={setOpenInfoGen}
            handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
            handleOpenCertificationError={handleOpenCertificationError}
            request={request}
            setRequest={setRequest}
          />

          {currentTag?.targets?.length !== 0 && (
            <ConfigGenerator
              tag={tag}
              targets={currentTag.targets}
              types={types}
              setTypes={setTypes}
              open={openConfigurationGen}
              setOpen={setOpenConfigurationGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          {conservSostL1 && (
            <CDNFileGenerator
              tag={currentTag}
              types={types}
              setTypes={setTypes}
              open={openUploadGen}
              setOpen={setOpenUploadGen}
              handleOpenCertificationSuccessful={handleOpenCertificationSuccessful}
              handleOpenCertificationError={handleOpenCertificationError}
              request={request}
              setRequest={setRequest}
            />
          )}

          <CertificationSuccessful open={openCertificationSuccessful} setOpen={setOpenCertificationSuccessful} />
          <CertificationError open={openCertificationError} setOpen={setOpenCertificationError} />
        </>
      )}
    </>
  );
};

export default Tag;
