// Firebase
import { db } from "config/firebase";
import { updateDoc, doc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const updateForwardRoute = async (tagID, route, body) => {
  try {
    const { endpoint, type, status, selectedTypes, headers } = body;
    const forwardRouteDocRef = doc(db, "tagsdata", tagID, "forward_routes", route);

    await updateDoc(forwardRouteDocRef, {
      endpoint,
      type,
      enabled: status,
      types: selectedTypes,
      headers,
      last_modified: unixTimestampInSeconds(),
    });

    console.log(`Document with route ID ${route} in tag ID ${tagID} has been successfully updated.`);
  } catch (error) {
    console.error(`Error in updateForwardRoute for route ID ${route} in tag ID ${tagID}:`, error.message);
  }
};

export default updateForwardRoute;
