// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// UserOperations
import addSignature from "UserOperations/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamCDNFile = async (userID, dataBody, dataCertification, timestamp, tdr) => {
  try {
    // Record transaction id (txid)
    const txid = dataCertification.txid;

    // Create a new record in "recordsdata" collection, using txid as document id
    const recordRef = doc(db, "recordsdata", txid);

    // Set the new record
    const recordData = {
      txid: txid,
      type: "CDNFile",
      version: 1,
      tdr: tdr,
      creator_uuid: userID,
      data: {
        attachment: dataBody.downloadURL,
        fileName: dataBody.fileName,
        fileType: dataBody.fileType,
        timestamp: timestamp,
      },
      fileName: dataBody.fileName,
      fileType: dataBody.fileType,
      timestamp: timestamp,
      uploadID: dataBody.uploadID,
      ...dataBody.documentProperties,
      AIC_ID: null,
    };

    console.log(recordData);

    await setDoc(recordRef, recordData);

    // Create the record link to "tagsdata" collection, using txid as foreign key
    const tagRef = doc(db, "tagsdata", tdr);

    // Signatures collection reference related to the tag
    const recordsRef = collection(tagRef, "signatures");

    // Set the new record link to the "signatures" subcollection
    const signatureData = {
      txid: txid,
      type: "CDNFile",
      creator_uuid: userID,
      db_id: 1,
      fileName: dataBody.fileName,
      fileType: dataBody.fileType,
      timestamp: timestamp,
      uploadID: dataBody.uploadID,
      ...dataBody.documentProperties,
      AIC_ID: null,
    };

    console.log(signatureData);

    await setDoc(doc(recordsRef, txid), signatureData);

    // Add the signature to "mysigs" subcollection of "userdata" collection
    await addSignature(userID, txid, timestamp, "CDNFile", tdr);

    // Update the "uploadStatuses" collection with the document id "uploadID"
    const uploadStatusRef = doc(db, "uploadStatuses", dataBody.uploadID);

    // Set the txid attribute in the uploadStatuses document
    const uploadStatusData = {
      txid: txid,
    };

    console.log(uploadStatusData);

    await setDoc(uploadStatusRef, uploadStatusData, { merge: true });

    const result = {
      txid: txid,
      success: true,
    };

    console.log(result);

    return result;
  } catch (error) {
    console.error("Error in sendDataStreamCDNFile:", error.message);

    const result = {
      success: false,
      error: error.message,
    };

    return result;
  }
};

export default sendDataStreamCDNFile;
