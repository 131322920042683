// Firebase
import { db } from "config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeAuthorizedKey = async (tagID, keyID) => {
  try {
    const authorizedKeyRef = doc(db, "tagsdata", tagID, "authorized_keys", keyID);
    await deleteDoc(authorizedKeyRef);
  } catch (error) {
    console.error(`Error in removeAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error.message);
  }
};

export default removeAuthorizedKey;
