// SafeTwin
import { genRndStringAlphanumericLowercase } from "SafeTwin/crypto/cryptolibsodium";

// sendDataStreamCDNFile
import sendDataStreamCDNFile from "./sendDataStreamCDNFile";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genCDNFileRecord = async (userID, dataBody, databoxID) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (!databoxID) {
      console.error("Error in genCDNFileRecord: missing databoxID");
      return {
        success: false,
        error: "Error in genCDNFileRecord: missing databoxID",
      };
    }

    // Mocked certification stream
    const dataCertification = {
      txid: genRndStringAlphanumericLowercase(64),
    };

    if (!dataCertification.txid) {
      console.error("Error in genCDNFileRecord: missing txid");
      return {
        success: false,
        error: "Error in genCDNFileRecord: missing txid",
      };
    }

    // Data stream
    const result = await sendDataStreamCDNFile(userID, dataBody, dataCertification, timestamp, databoxID);
    console.log(result);
    return result;
  } catch (error) {
    console.error("Error in genCDNFileRecord:", error.message);
    const result = {
      success: false,
      error: error.message,
    };
    console.log(result);
    return result;
  }
};

export default genCDNFileRecord;
