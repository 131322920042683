// React
import React, { useState } from "react";

// Prop-types
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { Box, Grid, Table, TableCell, TableHead, TableRow, TableContainer, TableFooter, TablePagination, IconButton, Typography, useMediaQuery, useTheme, Divider } from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
} from "@mui/icons-material";

// Components
import CertificationsTableStandard from "./CertificationsTableStandard";
import CertificationsTableConservSostL1 from "./CertificationsTableConservSostL1";

// A ---------------------------------------------------------------------- M

const CDNFileTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t("file_name")}</TableCell>
        <TableCell>{t("upload_date")}</TableCell>
        <TableCell>{t("archive")}</TableCell>
        <TableCell>{t("document_type")}</TableCell>
        <TableCell align="right">{t("actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const CertificationsTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{""}</TableCell>
        <TableCell>{t("generation_date")}</TableCell>
        <TableCell>{t("databox")}</TableCell>
        <TableCell>{t("type")}</TableCell>
        <TableCell>{t("visibility")}</TableCell>
        <TableCell align="right">{t("actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const CertificationsTableToolbar = () => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h5" fontWeight="bold">
          {conservSostL1 ? t("latest_documents") : t("latest_certifications")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CertificationsTable = ({ signatures, handleView, checkVerification, checkPreservationProof, handleFileClick }) => {
  console.log("Signatures:", signatures);

  const { conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - signatures.length) : 0;

  return (
    <Box>
      <CertificationsTableToolbar />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          {conservSostL1 ? <CDNFileTableHead /> : <CertificationsTableHead />}
          {conservSostL1 ? (
            <CertificationsTableConservSostL1
              signatures={signatures}
              handleView={handleView}
              checkPreservationProof={checkPreservationProof}
              emptyRows={emptyRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handleFileClick={handleFileClick}
            />
          ) : (
            <CertificationsTableStandard
              signatures={signatures}
              handleView={handleView}
              checkVerification={checkVerification}
              emptyRows={emptyRows}
              rowsPerPage={rowsPerPage}
              page={page}
              handleFileClick={handleFileClick}
            />
          )}
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={signatures.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

CertificationsTable.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkVerification: PropTypes.func.isRequired,
};

export default CertificationsTable;
