// Firebase
import { db } from "config/firebase";
import { collection, getDocs } from "firebase/firestore";

// GroupOperations
import getGroup from "GroupOperations/getGroup";

// UserOperations
import getUserContacts from "UserOperations/getUserContacts";

// A ---------------------------------------------------------------------- M

const getViewGroups = async (tagID) => {
  try {
    const viewgroupsRef = collection(db, "tagsdata", tagID, "viewgroups");
    const viewgroupsSnapshot = await getDocs(viewgroupsRef);

    const viewgroupsPromises = viewgroupsSnapshot.docs.map(async (viewgroupDoc) => {
      const group = await getGroup(viewgroupDoc.id);
      const creatorContacts = group?.creator_uuid ? await getUserContacts(group.creator_uuid) : {};
      const creatorEmail = creatorContacts.email || "";

      return {
        ...viewgroupDoc.data(),
        ...group,
        creator_email: creatorEmail,
        permissions: "r/w",
      };
    });

    const groups = await Promise.all(viewgroupsPromises);
    return groups;
  } catch (error) {
    console.error(`Error in getViewGroups for tagID ${tagID}:`, error.message);
    return [];
  }
};

export default getViewGroups;
