// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const InsertPassword = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const passwordForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          required
          fullWidth
          id={`Password-${props.label}`}
          label={t("password")}
          name="Password"
          type="password"
          value={props.password}
          onChange={(e) => props.setPassword(e.target.value)}
        />
        {props.invalidPassword && (
          <Typography color="red">
            {t("invalid_password")}. {t("try_again")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={props.handleClose}
      onOpen={() => props.setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {props.text}
            </Typography>
          </Grid>
        </Grid>
        {passwordForm}
        <Grid container mt="3%">
          <Grid item xs={12}>
            <Button fullWidth variant="contained" disabled={!props.password} onClick={props.handleAction}>
              {props.action}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {props.text}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={props.handleClose} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {passwordForm}
        <Grid item container xs={12} mt="3%">
          <Grid item xs={12}>
            <Button fullWidth variant="contained" disabled={!props.password} onClick={props.handleAction}>
              {props.action}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InsertPassword;
