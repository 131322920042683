// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addViewGroupReadOnly = async (tagID, groupID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const viewgroupsRef = collection(tagRef, "viewgroups_readonly");
    const newViewGroupRef = doc(viewgroupsRef, groupID);
    const newViewGroupSnapshot = await getDoc(newViewGroupRef);

    if (newViewGroupSnapshot.exists()) {
      return { code: -1 };
    }

    await setDoc(newViewGroupRef, {
      group_id: groupID,
      added_on: unixTimestampInSeconds(),
    });

    return { code: 0 };
  } catch (error) {
    console.error(`Error in addViewGroupReadOnly for tagID ${tagID} and groupID ${groupID}:`, error.message);
    return { code: -2 };
  }
};

export default addViewGroupReadOnly;
