// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTagInfo = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const tagSnapshot = await getDoc(tagRef);

    if (!tagSnapshot.exists()) {
      return null;
    }

    return {
      id: tagSnapshot.id,
      ...tagSnapshot.data(),
    };
  } catch (error) {
    console.error(`Error in getTagInfo for tagID ${tagID}:`, error.message);
    return null;
  }
};

export default getTagInfo;
