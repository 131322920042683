// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const doesTagExist = async (tagID) => {
  try {
    const docRef = doc(db, "tagsdata", tagID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(`Error in doesTagExist for tagID ${tagID}:`, error.message);
    return false;
  }
};

export default doesTagExist;
