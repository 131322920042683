import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getPubKeyUrl = async (uuid) => {
  try {
    const userRef = doc(db, "usercontacts", uuid);
    const userDoc = await getDoc(userRef);
    return userDoc.exists() ? userDoc.data().pubkeyurl ?? "" : "";
  } catch (error) {
    console.error("Error in getPubKeyUrl:", error.message);
    return "";
  }
};

export default getPubKeyUrl;
