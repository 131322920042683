// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// Components
import LikeSirtiTable from "./LikeSirtiTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const LikeSirtiViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  useEffect(() => {
    const tagsdataDocRef = doc(db, "tagsdata", tag.id);
    const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
    const currentTimestampInSecondsString = (Date.now() / 1000).toString();

    const newAdditionsQuery = query(signaturesCollectionRef, where("type", "==", "like_sirti"), where("timestamp", ">=", currentTimestampInSecondsString));
    const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          getRecords();
        }
      });
    });

    const getRecords = async () => {
      const records = await fetchRecordsByType(tag.id, "like_sirti", isTagGroupMember);
      records.sort((a, b) => {
        const timestampA = a.timestamp ?? 0;
        const timestampB = b.timestamp ?? 0;
        return timestampB - timestampA;
      });

      setRecords(records);
    };

    getRecords();

    return () => {
      newAdditionsUnsubscribe();
    };
  }, [tag, isTagGroupMember]);

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleOpenView = () => {
    setOpenView(true);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      let verification;
      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }
      setVerification(verification);
      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const handleView = (record) => {
    setRecord(record);
    handleOpenView();
  };

  return (
    <>
      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {records.length !== 0 && <LikeSirtiTable records={records} checkVerification={checkVerification} handleView={handleView} />}

      {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} />}

      {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}
    </>
  );
};

export default LikeSirtiViewer;
