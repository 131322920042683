// sendCertificationStreamImage
import sendCertificationStreamImage from "./sendCertificationStreamImage";

// sendDataStreamImage
import sendDataStreamImage from "./sendDataStreamImage";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genImageRecord = async (isPublic, userID, keypair, dataBody, databoxID) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (databoxID) {
      // Certification stream
      const dataCertification = await sendCertificationStreamImage(keypair, dataBody, timestamp, databoxID);

      if (!dataCertification.txid) {
        console.error("Error in genImageRecord: missing txid");
        return {
          success: false,
          error: "Error in genImageRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamImage(isPublic, userID, dataBody, dataCertification, timestamp, databoxID);
      console.log(result);
      return result;
    } else {
      // Certification stream
      const dataCertification = await sendCertificationStreamImage(keypair, dataBody, timestamp);

      if (!dataCertification.txid) {
        console.error("Error in genImageRecord: missing txid");
        return {
          success: false,
          error: "Error in genImageRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamImage(isPublic, userID, dataBody, dataCertification, timestamp);
      console.log(result);
      return result;
    }
  } catch (error) {
    console.error("Error in genImageRecord:", error.message);
    const result = {
      success: false,
      error: error.message,
    };
    console.log(result);
    return result;
  }
};

export default genImageRecord;
