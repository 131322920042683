// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const checkIfUserIsFollowingTag = async (uid, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (!tagDocSnapshot.exists()) {
      return false;
    }

    return tagDocSnapshot.data()?.followers?.includes(uid) || false;
  } catch (error) {
    console.error(`Error in checkIfUserIsFollowingTag for uid ${uid} and tag ${tagID}:`, error.message);
    return false;
  }
};

export default checkIfUserIsFollowingTag;
