// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { useMediaQuery, Grid, Typography, Button, Stack, IconButton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// Material UI Icons
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsNone as NotificationsNoneIcon,
  QrCode as QrCodeIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Settings as SettingsIcon,
  Stream as StreamIcon,
  ExpandMore as ExpandMoreIcon,
  CallReceived as CallReceivedIcon,
} from "@mui/icons-material";

// Components
import TagQRCode from "./TagQRCode";

// Utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const TagToolbar = ({ tag, isFollowing, handleFollowToggle }) => {
  const { user, conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openQR, setOpenQR] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleQRCode = () => {
    setOpenQR(true);
    window.history.pushState(null, "");
  };

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  const TagInformation = ({ tag, conservSostL1, t }) => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={1.5}>
          <Typography variant="body2">
            <b>{t("id")}:</b> {tag.id}
          </Typography>
          <Typography variant="body2">
            <b>{t("name")}:</b> {tag.name || "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("description")}:</b> {tag.notes || "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("registration_date")}:</b> {tag.firstseen ? convertTimestampDate(tag.firstseen) : "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("owner")}:</b> {tag.tagOwnerEmail || "N/A"}
          </Typography>
          {conservSostL1 && (
            <Typography variant="body2">
              <b>{t("document_type")}:</b> {tag.tipologiaDocumentale || "N/A"}
            </Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} data-testid="databox-information-card">
        <Grid item xs={12}>
          <Accordion expanded={accordionOpen} onChange={handleAccordionToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" fontWeight="bold">
                {tag.name || "N/A"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TagInformation tag={tag} conservSostL1={conservSostL1} t={t} />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {isMobile ? (
          <Grid item container xs={12} spacing={0.5} justifyContent="flex-start">
            <Grid item>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}`)}>
                <PlaylistAddCheckIcon fontSize="large" />
              </IconButton>
            </Grid>
            {tag.tagOwner === user.uid && (
              <>
                <Grid item>
                  <IconButton color="primary" onClick={() => navigate(`/${tag.id}/requests`)}>
                    <CallReceivedIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton color="primary" onClick={() => navigate(`/${tag.id}/settings`)}>
                    <SettingsIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </>
            )}
            <Grid item>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}/monitor`)}>
                <StreamIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleQRCode}>
                <QrCodeIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleFollowToggle}>
                {isFollowing ? <NotificationsNoneIcon fontSize="large" /> : <NotificationsActiveIcon fontSize="large" />}
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid item container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<PlaylistAddCheckIcon fontSize="large" />} variant="contained" color="primary" fullWidth onClick={() => navigate(`/${tag.id}`)}>
                {conservSostL1 ? t("documents") : t("certifications")}
              </Button>
            </Grid>
            {tag.tagOwner === user.uid && (
              <>
                {!conservSostL1 && (
                  <Grid item xs={12} sm={6} md={3} lg={1.5}>
                    <Button startIcon={<CallReceivedIcon fontSize="large" />} variant="contained" color="primary" fullWidth onClick={() => navigate(`/${tag.id}/requests`)}>
                      {t("requests")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Button startIcon={<SettingsIcon fontSize="large" />} variant="contained" fullWidth onClick={() => navigate(`/${tag.id}/settings`)}>
                    {t("settings")}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<StreamIcon fontSize="large" />} variant="contained" fullWidth onClick={() => navigate(`/${tag.id}/monitor`)}>
                {t("monitor")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<QrCodeIcon fontSize="large" />} variant="contained" fullWidth onClick={handleQRCode}>
                {t("qr_code")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button startIcon={isFollowing ? <NotificationsNoneIcon fontSize="large" /> : <NotificationsActiveIcon fontSize="large" />} variant="contained" fullWidth onClick={handleFollowToggle}>
                {isFollowing ? t("unfollow") : t("follow")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TagQRCode tag={tag.id} open={openQR} setOpen={setOpenQR} />
    </>
  );
};

export default TagToolbar;
