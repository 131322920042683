// React
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// Prop-types
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// Utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const GroupsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_date") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const GroupsTableToolbar = ({ handleOpen, searchQuery, handleSearchChange }) => {
  const { canCreateGroup, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" fontWeight="bold">
          {t("my_groups")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            placeholder={t("filter_by_name")}
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{ sx: { py: { xs: 0.7, sm: 1.5 }, fontSize: { xs: "0.8rem", sm: "1rem" } } }}
          />
        </Grid>
        {(canCreateGroup || conservSostL1) && (
          <Grid item container xs={0} sm={3} justifyContent="flex-end">
            {isMobile ? null : (
              <Button variant="contained" fullWidth sx={{ py: 1.5 }} onClick={handleOpen} startIcon={<AddIcon />} data-testid="create-group-button">
                {t("new_group")}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const GroupsTable = ({ groups, handleOpenGroupGenerator }) => {
  console.log("Groups:", groups);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGroups, setFilteredGroups] = useState(groups);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  useEffect(() => {
    const filtered = groups.filter((group) => group.name.toLowerCase().includes(searchQuery.toLowerCase()));
    setFilteredGroups(filtered);
    setPage(0);
  }, [searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredGroups.length) : 0;

  return (
    <Box>
      <GroupsTableToolbar handleOpen={handleOpenGroupGenerator} searchQuery={searchQuery} handleSearchChange={handleSearchChange} />
      <br />
      <Divider />
      <TableContainer>
        <Table data-testid="groups-table">
          {!isMobile && <GroupsTableHead />}
          <TableBody>
            {(isMobile ? filteredGroups : filteredGroups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((group) => {
              return isMobile ? (
                <Box key={group.id} onClick={() => navigate(`/${group.id}/group-settings`)} sx={{ padding: 2, borderBottom: "1px solid #e0e0e0", cursor: "pointer" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {group.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">{group.created_on ? convertTimestampDate(group.created_on) : "N/A"}</Typography>
                </Box>
              ) : (
                <TableRow
                  key={group.id}
                  sx={{
                    "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#bbdefb" },
                  }}
                  onClick={() => navigate(`/${group.id}/group-settings`)}
                >
                  <TableCell align="left">{group.name || "N/A"}</TableCell>
                  <TableCell align="left">{group.created_on ? convertTimestampDate(group.created_on) : "N/A"}</TableCell>
                </TableRow>
              );
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={2}
                  count={filteredGroups.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

GroupsTable.propTypes = {
  groups: PropTypes.array.isRequired,
  handleOpenGroupGenerator: PropTypes.func.isRequired,
};

export default GroupsTable;
