// Firebase
import { db } from "config/firebase";
import { deleteDoc, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeForwardRoute = async (routeID, tagID) => {
  try {
    const forwardRouteDocRef = doc(db, "tagsdata", tagID, "forward_routes", routeID);
    await deleteDoc(forwardRouteDocRef);
    console.log(`Document with route ID ${routeID} from tag ID ${tagID} has been successfully deleted.`);
  } catch (error) {
    console.error(`Error in removeForwardRoute for route ID ${routeID} and tag ID ${tagID}:`, error.message);
  }
};

export default removeForwardRoute;
