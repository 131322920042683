// sendCertificationStreamDoc
import sendCertificationStreamDoc from "./sendCertificationStreamDoc";

// sendDataStreamDoc
import sendDataStreamDoc from "./sendDataStreamDoc";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genDocRecord = async (isPublic, userID, keypair, dataBody, databoxID) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (databoxID) {
      // Certification stream
      const dataCertification = await sendCertificationStreamDoc(keypair, dataBody, timestamp, databoxID);

      if (!dataCertification.txid) {
        console.error("Error in genDocRecord: missing txid");
        return {
          success: false,
          error: "Error in genDocRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamDoc(isPublic, userID, dataBody, dataCertification, timestamp, databoxID);
      console.log(result);
      return result;
    } else {
      // Certification stream
      const dataCertification = await sendCertificationStreamDoc(keypair, dataBody, timestamp);

      if (!dataCertification.txid) {
        console.error("Error in genDocRecord: missing txid");
        return {
          success: false,
          error: "Error in genDocRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamDoc(isPublic, userID, dataBody, dataCertification, timestamp);
      console.log(result);
      return result;
    }
  } catch (error) {
    console.error("Error in genDocRecord:", error.message);
    const result = {
      success: false,
      error: error.message,
    };
    console.log(result);
    return result;
  }
};

export default genDocRecord;
