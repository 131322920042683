// Firebase
import { db } from "config/firebase";
import { collection, getDocs, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getForwardRoutes = async (tagID) => {
  try {
    const forwardRoutesRef = collection(db, "tagsdata", tagID, "forward_routes");
    const forwardRoutesSnapshot = await getDocs(forwardRoutesRef);

    return forwardRoutesSnapshot.docs.map((routeDoc) => ({
      id: routeDoc.id,
      ...routeDoc.data(),
    }));
  } catch (error) {
    console.error(`Error in getForwardRoutes for tagID ${tagID}:`, error.message);
    return [];
  }
};

export default getForwardRoutes;
