// React
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const ActivatedPubKeyTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("activation_date") },
      { id: "value", label: t("value") },
      { id: "type", label: t("type") },
      { id: "company", label: t("company") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ActivatedPubKeyTableToolbar = ({ handleOpen }) => {
  const { isReseller } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={5}>
        <Typography variant="h6" fontWeight="bold">
          {t("activated_public_keys_service_level_2")}
        </Typography>
      </Grid>
      {isReseller && (
        <Grid item xs={12} md={7}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} md={3}>
              <Button variant="contained" fullWidth onClick={handleOpen} startIcon={<AddIcon />}>
                {t("activate_public_key")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataRow = ({ publicKeyData }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const toggleVisibility = () => {
    setShow(!show);
  };

  return (
    <TableRow sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}>
      <TableCell align="left">{publicKeyData.name || "N/A"}</TableCell>
      <TableCell align="left">{publicKeyData.added_on ? convertTimestamp(publicKeyData.added_on) : "N/A"}</TableCell>
      <TableCell align="left" sx={{ minWidth: "180px", maxWidth: "180px" }} size="small">
        <span style={{ marginRight: "10px" }}>{show ? publicKeyData.APIKey : "••••••"}</span>
        <Tooltip title={show ? t("hide") : t("show")} placement="top">
          <IconButton onClick={toggleVisibility} color="primary">
            {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell align="left">{publicKeyData.company || "N/A"}</TableCell>
      <TableCell align="left">{publicKeyData.type || "N/A"}</TableCell>
    </TableRow>
  );
};

const ActivatedPubKeyTable = ({ publicKeysData, handleOpen }) => {
  console.log(publicKeysData);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - publicKeysData.length) : 0;

  return (
    <Box>
      <ActivatedPubKeyTableToolbar handleOpen={handleOpen} />
      <br />
      <Divider />
      <TableContainer>
        <Table>
          <ActivatedPubKeyTableHead />
          <TableBody>
            {(rowsPerPage > 0 ? publicKeysData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : publicKeysData).map((publicKeyData) => {
              return <DataRow key={publicKeyData.publicKey} publicKeyData={publicKeyData} />;
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={publicKeysData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) =>
                    t("displayed_rows", {
                      from,
                      to,
                      count: count !== -1 ? count : `more than ${to}`,
                    })
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
        {isMobile && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TablePaginationActions count={publicKeysData.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

ActivatedPubKeyTable.propTypes = {
  publicKeysData: PropTypes.object.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default ActivatedPubKeyTable;
