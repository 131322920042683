// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// UserOperations
import getUserContacts from "UserOperations/getUserContacts";

// TagOperations
import getCertificationsByCreator from "TagOperations/getCertificationsByCreator";

// A ---------------------------------------------------------------------- M

const getAuthorizedUsersData = async (databoxID) => {
  try {
    // Reference to the databox document in both collections
    const databoxRef = doc(db, "tagpermissions", databoxID);
    const databoxReadonlyRef = doc(db, "tagpermissions_readonly", databoxID);

    // References to the 'authorized_users' collections
    const authorizedUsersRef = collection(databoxRef, "authorized_users");
    const readonlyUsersRef = collection(databoxReadonlyRef, "authorized_users");

    // Getting the documents
    const [snapshot, readonlySnapshot] = await Promise.all([getDocs(authorizedUsersRef), getDocs(readonlyUsersRef)]);

    // Initialize total certifications counter and an array to track users
    let totalNumberOfCertifications = 0;
    const usersArray = [];

    const processUser = async (authUser, source) => {
      // Check if this user is already in the array
      const existingUser = usersArray.find((user) => user.user_id === authUser.id);

      if (existingUser) {
        // If the user exists, update the source information
        if (!existingUser.source.includes(source)) {
          existingUser.source.push(source);
        }
      } else {
        // Get user contacts
        const userContacts = await getUserContacts(authUser.id);

        // Get user certifications for this databox
        const userCertifications = await getCertificationsByCreator(databoxID, authUser.id);

        // Accumulate the total number of certifications
        totalNumberOfCertifications += userCertifications.number_of_certifications || 0;

        // Store user data in the array
        usersArray.push({
          user_id: authUser.id,
          user_email: userContacts.email || "N/A",
          user_public_key: userContacts.public_key || "N/A",
          user_company: userContacts.company || "N/A",
          user_certifications: userCertifications.certifications || [],
          user_number_of_certifications: userCertifications.number_of_certifications || 0,
          source: [source],
        });
      }
    };

    // Process authorized users from both collections sequentially
    for (const doc of snapshot.docs) {
      await processUser(doc, "read/write");
    }

    for (const doc of readonlySnapshot.docs) {
      await processUser(doc, "read-only");
    }

    console.log("authorizedUsersArray:", usersArray);
    console.log("totalNumberOfCertifications:", totalNumberOfCertifications);

    console.log(usersArray);

    return {
      authorizedUsersArray: usersArray,
      totalNumberOfCertifications,
    };
  } catch (e) {
    console.error("Error in getAuthorizedUsersData:", e.message);
    return {
      authorizedUsersArray: [],
      totalNumberOfCertifications: 0,
    };
  }
};

export default getAuthorizedUsersData;
