// React
import React, { useEffect } from "react";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// Components
import { Puller } from "ui-components/Puller";

// Metadata schema
import { metadataSchema, metadataSections } from "./metadataSchema";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CDNFileDetailsDialog = ({ open, onClose, record }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  const DetailsForm = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {t("id")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {record.id || "N/A"}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {t("status")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {t("uploaded")}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {t("upload_date")}
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {record.timestamp ? convertTimestamp(record.timestamp) : ""}
          </Typography>
        </Typography>
      </Grid>

      {metadataSections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {section.title === "Soggetti" && record.soggetti
            ? record.soggetti.map((soggetto, soggettoIndex) => (
                <React.Fragment key={soggettoIndex}>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold">
                      {`${t("Soggetto")} ${soggettoIndex + 1}`}
                    </Typography>
                    <Divider />
                  </Grid>
                  {Object.keys(soggetto).map((key, keyIndex) =>
                    soggetto[key] ? (
                      <Grid item xs={12} sm={12} md={6} key={keyIndex}>
                        <Typography variant="body1" fontWeight="bold">
                          {metadataSchema[key]?.label || key}
                          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                            {soggetto[key]}
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : null
                  )}
                </React.Fragment>
              ))
            : section.fields.map((fieldKey, fieldIndex) =>
                record[fieldKey] ? (
                  <Grid item xs={12} sm={12} md={6} key={fieldIndex}>
                    <Typography variant="body1" fontWeight="bold">
                      {metadataSchema[fieldKey]?.label || fieldKey}
                      <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                        {record[fieldKey]}
                      </Typography>
                    </Typography>
                  </Grid>
                ) : null
              )}
        </React.Fragment>
      ))}
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
            </Grid>
            {DetailsForm}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("details")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose} edge="end" sx={{ color: "red" }} aria-label={t("close")}>
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{DetailsForm}</DialogContent>
        </Dialog>
      )}
    </>
  );
};

CDNFileDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNFileDetailsDialog;
