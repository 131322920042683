const fileToUint8Array = async (file) => {
  try {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const uint8array = new Uint8Array(arrayBuffer);
        resolve(uint8array);
      };

      reader.onerror = () => {
        reject(reader.error);
      };
    });
  } catch (e) {
    console.error("Convert file to uint8Array failed: ", e.message);
  }
};

export default fileToUint8Array;
