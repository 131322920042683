// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Components
import TagToolbar from "ui-components/DataboxManagement/TagToolbar";
import ViewGroupsTable from "ui-components/DataboxManagement/ViewGroupsTable";
import EnabledDataKeysTable from "ui-components/DataboxManagement/EnabledDataKeysTable";
import ForwardRoutesTable from "ui-components/DataboxManagement/ForwardRoutesTable";
import AddViewGroup from "ui-components/DataboxManagement/AddViewGroup";
import AddDataKey from "ui-components/DataboxManagement/AddDataKey";
import NewTargetEndpoint from "ui-components/DataboxManagement/NewTargetEndpoint";
import EditForwardRoute from "ui-components/DataboxManagement/EditForwardRoute";
import {
  GroupAddedSuccessfulSnackbar,
  GroupAddedErrorSnackbar,
  GroupRemovedSuccessfulSnackbar,
  GroupRemovedErrorSnackbar,
  ForwardRouteRemovedSuccessfulSnackbar,
  ForwardRouteRemovedErrorSnackbar,
} from "ui-components/ORFeedbacks";

// Firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// TagOperations
import getTag from "TagOperations/getTag";
import getTagOwner from "TagOperations/getTagOwner";
import getViewGroups from "TagOperations/getViewGroups";
import getViewGroupsReadOnly from "TagOperations/getViewGroupsReadOnly";
import getAuthorizedDataKeys from "TagOperations/getAuthorizedDataKeys";
import getForwardRoutes from "TagOperations/getForwardRoutes";
import checkIfUserIsFollowingTag from "TagOperations/checkIfUserIsFollowingTag";
import addFollower from "TagOperations/addFollower";
import removeFollower from "TagOperations/removeFollower";

// UserOperations
import getSeenTag from "UserOperations/getSeenTag";
import getUserContacts from "UserOperations/getUserContacts";

// A ---------------------------------------------------------------------- M

const TagSettings = () => {
  const { user, conservSostL1 } = UserAuth();
  const { tag } = useParams();
  const { t } = useTranslation();

  const [seentag, setSeenTag] = useState();
  const [viewgroups, setViewGroups] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [forwardRoutes, setForwardRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();
  const [openAddViewGroup, setOpenAddViewGroup] = useState(false);
  const [openAddDataKey, setOpenAddDataKey] = useState(false);
  const [openAddForwardRoute, setOpenAddForwardRoute] = useState(false);
  const [openEditForwardRoute, setOpenEditForwardRoute] = useState(false);
  const [openGroupAddedSuccessful, setOpenGroupAddedSuccessful] = useState(false);
  const [openGroupAddedError, setOpenGroupAddedError] = useState(false);
  const [openGroupRemovedSuccessful, setOpenGroupRemovedSuccessful] = useState(false);
  const [openGroupRemovedError, setOpenGroupRemovedError] = useState(false);
  const [openForwardRouteRemovedSuccessful, setOpenForwardRouteRemovedSuccessful] = useState(false);
  const [openForwardRouteRemovedError, setOpenForwardRouteRemovedError] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSeenTag = async () => {
      const seentag = await getSeenTag(user.uid, tag);
      const tagData = await getTag(tag);
      const tagName = tagData.name;
      const tagOwner = await getTagOwner(tag);
      const tagOwnerData = await getUserContacts(tagOwner);
      const tagOwnerEmail = tagOwnerData.email;
      const updatedSeentag = {
        ...seentag,
        name: tagName,
        tagOwner: tagOwner,
        tagOwnerEmail: tagOwnerEmail,
      };

      if (conservSostL1 && tagData.tipologiaDocumentale) {
        updatedSeentag.tipologiaDocumentale = tagData.tipologiaDocumentale;
      }

      setSeenTag(updatedSeentag);
    };

    const fetchViewGroups = async () => {
      let viewgroups;

      if (conservSostL1) {
        const readOnlyGroups = await getViewGroupsReadOnly(tag);
        console.log(readOnlyGroups);
        const allPermissionsGroups = await getViewGroups(tag);
        viewgroups = [...readOnlyGroups, ...allPermissionsGroups];
      } else {
        viewgroups = await getViewGroups(tag);
      }

      setViewGroups(viewgroups);
    };

    const fetchDataKeys = async () => {
      const dataKeys = await getAuthorizedDataKeys(tag);
      setDataKeys(dataKeys.authorizedKeysArray);
    };

    const fetchForwardRoutes = async () => {
      const forwardRoutes = await getForwardRoutes(tag);
      setForwardRoutes(forwardRoutes);
    };

    const fetchData = async () => {
      await fetchSeenTag();
      await fetchViewGroups();
      await fetchDataKeys();
      await fetchForwardRoutes();
      setIsLoading(false);
    };

    fetchData();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    const tagsdataDocRef = doc(db, "tagsdata", tag);
    const viewgroupsCollectionRef = collection(tagsdataDocRef, "viewgroups");
    const viewgroupsReadOnlyCollectionRef = collection(tagsdataDocRef, "viewgroups_readonly");
    const authorizedKeysCollectionRef = collection(tagsdataDocRef, "authorized_keys");
    const forwardRoutesCollectionRef = collection(tagsdataDocRef, "forward_routes");

    const newAdditionsQueryViewGroups = query(viewgroupsCollectionRef, where("added_on", ">=", currentTimestampInSeconds.toString()));
    const newAdditionsQueryViewGroupsReadOnly = query(viewgroupsReadOnlyCollectionRef, where("added_on", ">=", currentTimestampInSeconds.toString()));
    const newAdditionsQueryDataKeys = query(authorizedKeysCollectionRef, where("added_on", ">=", currentTimestampInSeconds.toString()));
    const newAdditionsQueryForwardRoutes = query(forwardRoutesCollectionRef, where("added_on", ">=", currentTimestampInSeconds.toString()));

    const newAdditionsUnsubscribeViewGroups = onSnapshot(newAdditionsQueryViewGroups, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchViewGroups();
        }
      });
    });

    const newAdditionsUnsubscribeViewGroupsReadOnly = onSnapshot(newAdditionsQueryViewGroupsReadOnly, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchViewGroups();
        }
      });
    });

    const deletionUnsubscribeViewGroups = onSnapshot(viewgroupsCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchViewGroups();
        }
      });
    });

    const deletionUnsubscribeViewGroupsReadOnly = onSnapshot(viewgroupsReadOnlyCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchViewGroups();
        }
      });
    });

    const newAdditionsUnsubscribeDataKeys = onSnapshot(newAdditionsQueryDataKeys, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchDataKeys();
        }
      });
    });

    const deletionUnsubscribeDataKeys = onSnapshot(authorizedKeysCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchDataKeys();
        }
      });
    });

    const newAdditionsUnsubscribeForwardRoutes = onSnapshot(newAdditionsQueryForwardRoutes, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          fetchForwardRoutes();
        }
      });
    });

    const modifiyUnsubscribeForwardRoutes = onSnapshot(forwardRoutesCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          fetchForwardRoutes();
        }
      });
    });

    const deletionUnsubscribeForwardRoutes = onSnapshot(forwardRoutesCollectionRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "removed") {
          fetchForwardRoutes();
        }
      });
    });

    return () => {
      newAdditionsUnsubscribeViewGroups();
      newAdditionsUnsubscribeViewGroupsReadOnly();
      deletionUnsubscribeViewGroups();
      deletionUnsubscribeViewGroupsReadOnly();
      newAdditionsUnsubscribeDataKeys();
      deletionUnsubscribeDataKeys();
      newAdditionsUnsubscribeForwardRoutes();
      modifiyUnsubscribeForwardRoutes();
      deletionUnsubscribeForwardRoutes();
    };
  }, [tag, user]);

  useEffect(() => {
    const checkIsFollowing = async () => {
      const following = await checkIfUserIsFollowingTag(user.uid, tag);
      setIsFollowing(following);
    };

    checkIsFollowing();
  }, [user, tag]);

  const handleFollowToggle = async () => {
    if (isFollowing) {
      await removeFollower(user.uid, tag);
    } else {
      await addFollower(user.uid, tag);
    }
    setIsFollowing(!isFollowing);
  };

  const openAddViewGroupDrawer = () => {
    setOpenAddViewGroup(true);
    window.history.pushState(null, "");
  };

  const openAddDataKeyDialog = () => {
    setOpenAddDataKey(true);
    window.history.pushState(null, "");
  };

  const openAddForwardRouteDrawer = () => {
    setOpenAddForwardRoute(true);
    window.history.pushState(null, "");
  };

  const clickEdit = (route) => {
    setSelectedRoute(route);
    setOpenEditForwardRoute(true);
    window.history.pushState(null, "");
  };

  const handleOpenForwardRouteRemovedSuccessful = () => {
    setOpenForwardRouteRemovedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenForwardRouteRemovedError = () => {
    setOpenForwardRouteRemovedError(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupAddedSuccessful = () => {
    setOpenGroupAddedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupAddedError = () => {
    setOpenGroupAddedError(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupRemovedSuccessful = () => {
    setOpenGroupRemovedSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupRemovedError = () => {
    setOpenGroupRemovedError(true);
    window.history.pushState(null, "");
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item container xs={12} spacing={5}>
      {seentag && (
        <>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <TagToolbar tag={seentag} isFollowing={isFollowing} handleFollowToggle={handleFollowToggle} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} sm={10}>
                <Typography variant="h5" fontWeight="bold">
                  {t("settings")}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={5}>
              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderRadius: 2,
                  }}
                >
                  <CardContent>
                    <ViewGroupsTable
                      tag={seentag}
                      groups={viewgroups}
                      openAddViewGroupDrawer={openAddViewGroupDrawer}
                      handleOpenGroupRemovedSuccessful={handleOpenGroupRemovedSuccessful}
                      handleOpenGroupRemovedError={handleOpenGroupRemovedError}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card
                  variant="outlined"
                  sx={{
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderRadius: 2,
                  }}
                >
                  <CardContent>
                    <EnabledDataKeysTable tag={seentag} dataKeys={dataKeys} openAddDataKeyDialog={openAddDataKeyDialog} />
                  </CardContent>
                </Card>
              </Grid>

              {!conservSostL1 && (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderRadius: 2,
                    }}
                  >
                    <CardContent>
                      <ForwardRoutesTable
                        tag={seentag}
                        forwardRoutes={forwardRoutes}
                        openAddForwardRouteDrawer={openAddForwardRouteDrawer}
                        clickEdit={clickEdit}
                        handleSuccessfulRemoved={handleOpenForwardRouteRemovedSuccessful}
                        handleErrorRemoved={handleOpenForwardRouteRemovedError}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>

          <AddViewGroup tag={seentag} open={openAddViewGroup} setOpen={setOpenAddViewGroup} handleSuccessfulAdded={handleOpenGroupAddedSuccessful} handleErrorAdded={handleOpenGroupAddedError} />
          <GroupAddedSuccessfulSnackbar open={openGroupAddedSuccessful} setOpen={setOpenGroupAddedSuccessful} />
          <GroupAddedErrorSnackbar open={openGroupAddedError} setOpen={setOpenGroupAddedError} />
          <GroupRemovedSuccessfulSnackbar open={openGroupRemovedSuccessful} setOpen={setOpenGroupRemovedSuccessful} />
          <GroupRemovedErrorSnackbar open={openGroupRemovedError} setOpen={setOpenGroupRemovedError} />
          <AddDataKey tag={seentag} dataKeys={dataKeys} open={openAddDataKey} setOpen={setOpenAddDataKey} />
          <NewTargetEndpoint tag={seentag} open={openAddForwardRoute} setOpen={setOpenAddForwardRoute} />
          {selectedRoute && <EditForwardRoute tag={seentag} route={selectedRoute} open={openEditForwardRoute} setOpen={setOpenEditForwardRoute} />}
          <ForwardRouteRemovedSuccessfulSnackbar open={openForwardRouteRemovedSuccessful} setOpen={setOpenForwardRouteRemovedSuccessful} />
          <ForwardRouteRemovedErrorSnackbar open={openForwardRouteRemovedError} setOpen={setOpenForwardRouteRemovedError} />
        </>
      )}
    </Grid>
  );
};

export default TagSettings;
