// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTagOwner = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const tagSnapshot = await getDoc(tagRef);

    if (!tagSnapshot.exists()) {
      return "";
    }

    return tagSnapshot.data()?.owner_uuid || "";
  } catch (error) {
    console.error(`Error in getTagOwner for tagID ${tagID}:`, error.message);
    return "";
  }
};

export default getTagOwner;
