// Firebase
import { db } from "config/firebase";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addFollower = async (uid, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      await updateDoc(tagDocRef, {
        followers: arrayUnion(uid),
      });
      console.log(`Follow ${tagID}`);
    }
  } catch (error) {
    console.error(`Error in addFollower for uid ${uid} and tag ${tagID}:`, error.message);
  }
};

export default addFollower;
