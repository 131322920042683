// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";

// ChartJS
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

// A ---------------------------------------------------------------------- M

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MpsChart = ({ chartData }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: t("source_data"),
      },
    },
    scales: {
      x: {
        type: "time",
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        suggestedMin: 0,
        title: {
          display: true,
          text: "Value",
        },
      },
    },
  };

  return <Line options={options} data={chartData} height={isMobile ? "300vh" : "100vh"} />;
};

export default MpsChart;
