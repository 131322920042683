// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getClient = async (clientID) => {
  try {
    const clientDocRef = doc(db, "clientsdata", clientID);
    const clientDocSnapshot = await getDoc(clientDocRef);

    if (!clientDocSnapshot.exists()) {
      console.error(`No client found with ID ${clientID}`);
      return null;
    }

    return {
      id: clientDocSnapshot.id,
      ...clientDocSnapshot.data(),
    };
  } catch (error) {
    console.error(`Error fetching client with ID ${clientID}:`, error.message);
    return null;
  }
};

export default getClient;
