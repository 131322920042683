// React
import React, { useState, useEffect } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Contexts
import { UserAuth } from "context/AuthContext";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import { NewCertConfirm } from "ui-components/ORFeedbacks";

// SafeTwin
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";

// ConfigGenerator
import genConfigRecord from "generator/ConfigGenerator/genConfigRecord";

// A ---------------------------------------------------------------------- M

const ConfigGenerator = ({ tag, targets, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError, handleOpenViewModeWarning, request, setRequest }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [keypair, setKeyPair] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [targetPrototype, setTargetPrototype] = useState("");
  const [targetPrototypeMeta, setTargetPrototypeMeta] = useState("");
  const [propertyValues, setPropertyValues] = useState({});
  const [targetSelectionOpen, setTargetSelectionOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (targetSelectionOpen) {
        handleCloseTargetSelection();
      } else if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, targetSelectionOpen]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    setPropertyValues({});
  }, [selectedTarget]);

  const handleOpenTargetSelection = () => {
    setTargetSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleCloseTargetSelection = () => {
    setTargetSelectionOpen(false);
  };

  const handleGenerate = async () => {
    setConfirm(false);
    setLoading(true);

    const configObject = {};

    Object.entries(propertyValues).forEach(([key, value]) => {
      configObject[key] = value;
    });

    const jsonconfig = JSON.stringify(configObject);

    if (keypair) {
      try {
        const result = await genConfigRecord(user.uid, keypair, selectedTarget, jsonconfig, tag, checked);

        if (result.txid) {
          handleOpenCertificationSuccessful();
        } else {
          handleOpenCertificationError();
        }
      } catch (error) {
        handleOpenCertificationError();
      }
    } else {
      handleOpenViewModeWarning();
    }

    if (!types.includes("cfg")) {
      setTypes([...types, "cfg"]);
    }

    setLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setSelectedTarget("");
    setTargetPrototype("");
    setPropertyValues({});
    setOpen(false);
    setChecked(false);
  };

  const handleTargetChange = (event) => {
    const selectedTargetID = event.target.value;
    const selectedTarget = targets.find((target) => target.name === selectedTargetID);

    if (selectedTarget) {
      setSelectedTarget(selectedTargetID);
      setTargetPrototype(selectedTarget.prototype);

      if (selectedTarget.prototype_meta) {
        setTargetPrototypeMeta(selectedTarget.prototype_meta);
      }

      const initialValues = {};

      if (selectedTarget.prototype) {
        selectedTarget.prototype.split(";").forEach((property) => {
          initialValues[property] = "";
        });
      }

      setPropertyValues(initialValues);
    } else {
      setSelectedTarget("");
      setTargetPrototype("");
      setPropertyValues({});
    }
  };

  const GeneratorForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="gray">
          {t("select_target")}
        </Typography>
        <FormControl fullWidth>
          <Select id="target-select" value={selectedTarget} onChange={handleTargetChange} open={targetSelectionOpen} onOpen={handleOpenTargetSelection} onClose={handleCloseTargetSelection}>
            {targets.map((target) => (
              <MenuItem key={target.name} value={target.name}>
                {target.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {targetPrototype && (
        <Grid item container xs={12} spacing={2}>
          {targetPrototype.split(";").map((property, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="body2" color="gray">{`${property} *`}</Typography>
              {targetPrototypeMeta === "select" ? (
                <Select
                  fullWidth
                  variant="outlined"
                  value={propertyValues[property] || ""}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPropertyValues((prevValues) => ({
                      ...prevValues,
                      [property]: newValue,
                    }));
                  }}
                >
                  <MenuItem value="ON">ON</MenuItem>
                  <MenuItem value="OFF">OFF</MenuItem>
                </Select>
              ) : (
                <TextField
                  fullWidth
                  variant="outlined"
                  value={propertyValues[property] || ""}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setPropertyValues((prevValues) => ({
                      ...prevValues,
                      [property]: newValue,
                    }));
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "70%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_configuration")}
              </Typography>
            </Grid>
          </Grid>
          {loading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {GeneratorForm}
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" disabled={!selectedTarget} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                    {t("certify")}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>

      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_configuration")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {loading ? (
          <LinearLoadingComponent />
        ) : (
          <DialogContent dividers>
            {GeneratorForm}
            <Grid item container xs={12} spacing={1} mt="3%">
              <Grid item xs={12}>
                <Button fullWidth variant="contained" disabled={!selectedTarget} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                  {t("certify")}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
    </>
  );
};

export default ConfigGenerator;
