// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getUserContacts = async (uid) => {
  const defaultContacts = {
    email: "",
    public_key: "",
    company: "",
    clientID: "",
  };

  try {
    const userRef = doc(db, "usercontacts", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      return defaultContacts;
    }

    const { email = "", public_key = "", company = "", clientID = "" } = userDoc.data() || {};
    return { email, public_key, company, clientID };
  } catch (error) {
    console.error(`Error in getUserContacts for uuid ${uid}:`, error.message);
    return defaultContacts;
  }
};

export default getUserContacts;
