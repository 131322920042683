// React
import React from "react";
import { Link } from "react-router-dom";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { TableBody, TableCell, TableRow, IconButton, Tooltip } from "@mui/material";

// Material UI Icons
import { FileOpen as FileOpenIcon, Download as DownloadIcon, ManageSearch as ManageSearchIcon, Task as TaskIcon } from "@mui/icons-material";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// Storage
import downloadFile from "storage/downloadFile";

// A ---------------------------------------------------------------------- M

const CertificationsTableConservSostL1 = ({ signatures, handleView, checkPreservationProof, emptyRows, rowsPerPage, page, handleFileClick }) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      {(rowsPerPage > 0 ? signatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : signatures).map((signature) => (
        <TableRow
          key={signature.id}
          sx={{
            "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
            cursor: "pointer",
            "&:hover": { backgroundColor: "#bbdefb" },
          }}
        >
          <TableCell>{signature.nomeDelDocumento || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
          <TableCell>{signature.timestamp ? convertTimestamp(signature.timestamp) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
          <TableCell>
            {signature.name ? (
              <Link
                to={`/${signature.tdr}`}
                style={{
                  textDecoration: "none",
                  color: "primary.main",
                  fontWeight: "bold",
                }}
              >
                {signature.name}
              </Link>
            ) : (
              "Not assigned"
            )}
          </TableCell>
          <TableCell>{signature.tipologiaDocumentale || "N/A"}</TableCell>
          <TableCell align="right" size="small">
            <Tooltip title={t("details")}>
              <IconButton color="primary" onClick={() => handleView(signature)}>
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("open")} placement="top">
              <IconButton color="primary" onClick={() => handleFileClick(signature.data.attachment)}>
                <FileOpenIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("download")}>
              <IconButton color="primary" onClick={() => downloadFile(signature.data.attachment, signature.fileName)}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("preservation_proof")}>
              <IconButton sx={{ color: "green" }} onClick={() => checkPreservationProof(signature)}>
                <TaskIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={5} />
        </TableRow>
      )}
    </TableBody>
  );
};

CertificationsTableConservSostL1.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkPreservationProof: PropTypes.func.isRequired,
};

export default CertificationsTableConservSostL1;
