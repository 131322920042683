// Firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addAuthorizedKey = async (tagID, keyID) => {
  try {
    const authorizedKeyRef = doc(db, `tagsdata/${tagID}/authorized_keys`, keyID);
    const added_on = unixTimestampInSeconds();

    await setDoc(authorizedKeyRef, {
      key_id: keyID,
      added_on,
    });
  } catch (error) {
    console.error(`Error in addAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error.message);
  }
};

export default addAuthorizedKey;
