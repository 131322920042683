// React
import React, { useState } from "react";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Grid,
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useMediaQuery,
  useTheme,
  Tooltip,
  Divider,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ManageSearch as ManageSearchIcon,
  FileOpen as FileOpenIcon,
  Download as DownloadIcon,
  Task as TaskIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// Components
import CDNFileDetailsDialog from "./CDNFileDetailsDialog";

// Storage
import downloadFile from "storage/downloadFile";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CDNFileTableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t("file_name")}</TableCell>
        <TableCell>{t("upload_date")}</TableCell>
        <TableCell>{t("document_type")}</TableCell>
        <TableCell align="right">{t("actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const CDNFileTableToolbar = ({ isTagGroupMember, handleOpenFilterDialog, isTagGroupMemberReadWrite, openUploadGenDrawer }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" fontWeight="bold">
          {t("documents")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        {isTagGroupMember && (
          <Grid item xs={12} sm={4}>
            <Button variant="contained" fullWidth sx={{ py: 1.5 }} onClick={handleOpenFilterDialog} startIcon={<SearchIcon />}>
              {t("search")}
            </Button>
          </Grid>
        )}
        {isTagGroupMemberReadWrite && (
          <Grid item xs={12} sm={4}>
            <Button variant="contained" fullWidth sx={{ py: 1.5 }} onClick={openUploadGenDrawer} startIcon={<AddIcon />}>
              {t("upload")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const CDNFileTable = ({ records, handleOpenPreservationProofDialog, isTagGroupMember, handleOpenFilterDialog, isTagGroupMemberReadWrite, openUploadGenDrawer, handleFileClick }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDetails = (record) => {
    setSelectedRecord(record);
    setDetailsDialogOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsDialogOpen(false);
    setSelectedRecord(null);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  return (
    <>
      <Box>
        <CDNFileTableToolbar
          isTagGroupMember={isTagGroupMember}
          handleOpenFilterDialog={handleOpenFilterDialog}
          isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
          openUploadGenDrawer={openUploadGenDrawer}
        />
        <br />
        <Divider />
        <TableContainer>
          <Table size={isMobile ? "small" : "medium"}>
            <CDNFileTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : records).map((record) => {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                      cursor: "pointer",
                      "&:hover": { backgroundColor: "#bbdefb" },
                    }}
                  >
                    <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{record.fileName || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell>{convertTimestamp(record.timestamp) || "N/A"}</TableCell>
                    <TableCell sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>{record.tipologiaDocumentale || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell align="right" size="small">
                      <Tooltip title={t("details")} placement="top">
                        <IconButton color="primary" onClick={() => handleOpenDetails(record)}>
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("open")} placement="top">
                        <IconButton color="primary" onClick={() => handleFileClick(record.data.attachment)}>
                          <FileOpenIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("download")} placement="top">
                        <IconButton color="primary" onClick={() => downloadFile(record.data.attachment, record.fileName)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("preservation_proof")} placement="top">
                        <IconButton sx={{ color: "green" }} onClick={() => handleOpenPreservationProofDialog(record)}>
                          <TaskIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={5}
                    count={records.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Box>
      {selectedRecord && <CDNFileDetailsDialog open={detailsDialogOpen} setOpen={setDetailsDialogOpen} onClose={handleCloseDetails} record={selectedRecord} />}
    </>
  );
};

export default CDNFileTable;
