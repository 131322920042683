// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import { Grid, Box, IconButton, Dialog, Typography, CircularProgress, useMediaQuery } from "@mui/material";

// Material UI Icons
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// Components
import CertificationsTable from "ui-components/CertificationManagement/CertificationsTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import CDNFileDetailsDialog from "ui-components/DataTypeManagement/CDNFile/CDNFileDetailsDialog";
import CDNPreservationProofDialog from "ui-components/DataTypeManagement/CDNFile/CDNPreservationProofDialog";
import { LoadingDialog } from "ui-components/LoadingComponent";

// UserOperations
import fetchLastSignatures from "UserOperations/fetchLastSignatures";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyCertifications = () => {
  const { user, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [signatures, setSignatures] = useState([]);
  const [record, setRecord] = useState(null);
  const [verification, setVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogs, setDialogs] = useState({ view: false, verify: false, preservationProof: false });

  const toggleDialog = (dialog, state = true) => {
    setDialogs((prev) => ({ ...prev, [dialog]: state }));
    if (state) window.history.pushState(null, "");
  };

  const fetchUserData = useCallback(async () => {
    if (!user) return;
    try {
      const signatures = await fetchLastSignatures(user.uid);
      setSignatures(signatures.sort((a, b) => b.timestamp - a.timestamp));
    } catch (error) {
      console.log("Error in fetchUserData:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  useEffect(() => {
    const handlePopState = () => {
      if (dialogs.view) toggleDialog("view", false);
      if (dialogs.verify) toggleDialog("verify", false);
      if (dialogs.preservationProof) toggleDialog("preservationProof", false);
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs.view, dialogs.verify, dialogs.preservationProof]);

  const handleView = (record) => {
    setRecord(record);
    toggleDialog("view");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      const verification = await verifySignature(user?.uid || false, record, record.type);
      setVerification(verification);
      toggleDialog("verify");
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const checkPreservationProof = (record) => {
    setRecord(record);
    toggleDialog("preservationProof");
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      setSelectedFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType.startsWith("image/")) {
        setSelectedFile(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const renderFileDialog = () => {
    if (!selectedFileType) return null;

    switch (selectedFileType) {
      case "application/pdf":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <pre>{xmlContent}</pre>
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="sm">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton sx={{ color: "red" }} onClick={handleCloseDialog} aria-label="close">
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog} fullWidth maxWidth="md">
            <IconButton
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                zIndex: 1,
                color: "red",
              }}
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseOutlinedIcon />
            </IconButton>
            <img src={selectedFile} alt="img" />
          </Dialog>
        );

      default:
        return null;
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <CertificationsTable signatures={signatures} handleView={handleView} checkVerification={checkVerification} checkPreservationProof={checkPreservationProof} handleFileClick={handleFileClick} />

      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {record &&
        (conservSostL1 ? (
          <>
            <CDNFileDetailsDialog record={record} open={dialogs.view} onClose={() => toggleDialog("view", false)} />
            <CDNPreservationProofDialog
              open={dialogs.preservationProof}
              setOpen={() => toggleDialog("preservationProof", false)}
              onClose={() => toggleDialog("preservationProof", false)}
              record={record}
            />
          </>
        ) : (
          <DataDialog data={{ ...record, databoxName: record.name }} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
        ))}

      {verification && <DatapointIntegrityInspector verification={verification} open={dialogs.verify} setOpen={() => toggleDialog("verify", false)} />}

      {renderFileDialog()}
    </Grid>
  );
};

export default MyCertifications;
