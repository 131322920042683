// Firebase
import { db } from "config/firebase";
import {
  doc,
  collection,
  getDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const removeMemberFromGroup = async (memberUID, groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const usersRef = collection(groupRef, "users");
    const userRef = doc(usersRef, memberUID);

    await deleteDoc(userRef);

    await updateDoc(groupRef, {
      members: group.data().members - 1,
    });

    return { code: 0 };
  } catch (error) {
    console.error("Error in removeMemberFromGroup:", error);
    return { code: -1 };
  }
};

export default removeMemberFromGroup;
