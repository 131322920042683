import { decryptServerSeed } from "./decryptServerSeed";
import { entropyToMnemonic } from "SafeTwin/crypto/bip39/bip39";

// A ---------------------------------------------------------------------- M

/**
 * Retrieves and converts a user's decrypted seed into a BIP39 24-words mnemonic phrase.
 *
 * This function decrypts the server-stored seed for the given user using their password
 * and then converts the decrypted seed into a mnemonic phrase.
 *
 * @param {Object} user - The user object containing at least the uid property.
 * @param {string} password - The password of the user, used for decrypting the seed.
 * @returns {Promise<string | null>} A promise that resolves to the mnemonic phrase, or rejects if an error occurs.
 */
export const getMnemonic = async (user, password) => {
  try {
    const server_decrypted_seed = await decryptServerSeed(user.uid, password);

    const mnemonic = entropyToMnemonic(server_decrypted_seed);

    return mnemonic;
  } catch (e) {
    console.error("Error in getting mnemonic: ", e.message);
    return null;
  }
};
