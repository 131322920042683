// React
import React, { useState, useMemo } from "react";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  IconButton,
  useTheme,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const ConfigTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "parameters", label: t("parameters") },
      { id: "timestamp", label: t("generation_date") },
      { id: "creator", label: t("creator") },
      { id: "target", label: t("target") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RowWithAccordion = ({ record, checkVerification, isLastRecentRecord }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(isLastRecentRecord);

  const jsonConfig = JSON.parse(record.data.jsonconfig);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <TableRow
      key={record.id}
      sx={{
        "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
        cursor: "pointer",
        "&:hover": { backgroundColor: "#bbdefb" },
      }}
    >
      <TableCell>
        <Accordion expanded={expanded} onChange={toggleExpand}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="body2" color="gray">
              {t("show_parameters")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(jsonConfig).map(([key, value]) => (
              <div key={key}>
                <Typography variant="body2" fontWeight="bold">
                  {key}: {value}
                </Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      </TableCell>
      <TableCell align="left">{record.data.timestamp ? convertTimestamp(record.data.timestamp) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
      <TableCell align="left">{record.creator_email || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
      <TableCell align="left">{record.data.target || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
      <TableCell align="left" sx={{ color: "gray" }}>
        {record.public ? t("public") : t("private")}
      </TableCell>
      <TableCell align="right">
        <Tooltip title={t("verify")} placement="top">
          <IconButton sx={{ color: "green" }} onClick={() => checkVerification(record)}>
            <VerifiedIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const HistoryConfig = ({ records, checkVerification }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0;

  return (
    <TableContainer>
      <Table size="small">
        <ConfigTableHead />
        <TableBody>
          {(rowsPerPage > 0 ? records.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : records).map((record, index) => {
            const isLastRecentRecord = index === 0 && page === 0;
            return <RowWithAccordion key={`${record.id}-${isLastRecentRecord}`} record={record} checkVerification={checkVerification} isLastRecentRecord={isLastRecentRecord} />;
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {!isMobile && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, { label: "All", value: -1 }]}
                colSpan={6}
                count={records.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("rows_per_page")}
                labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

HistoryConfig.propTypes = {
  records: PropTypes.array.isRequired,
  checkVerification: PropTypes.func.isRequired,
};

export default HistoryConfig;
