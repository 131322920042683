// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getUserData = async (uuid) => {
  try {
    const userRef = doc(db, "userdata", uuid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      return {
        canCreateDatabox: userData.canCreateDatabox || false,
        canCreateGroup: userData.canCreateGroup || false,
        canSign: userData.canSign || false,
        isReseller: userData.isReseller || false,
        canAddUser: userData.canAddUser || false,
        conservSostL1: userData.conservSostL1 || false,
        APIKey: userData.APIKey || "",
        isInternal: userData.isInternal || false,
      };
    } else {
      return {
        canCreateDatabox: false,
        canCreateGroup: false,
        canSign: false,
        isReseller: false,
        canAddUser: false,
        conservSostL1: false,
        APIKey: "",
        isInternal: false,
      };
    }
  } catch (e) {
    console.error("Error in getUserData:", e.message);
    return {
      canCreateDatabox: false,
      canCreateGroup: false,
      canSign: false,
      isReseller: false,
      canAddUser: false,
      conservSostL1: false,
      APIKey: "",
      isInternal: false,
    };
  }
};

export default getUserData;
