// SafeTwin
import { compareSHA256 } from "SafeTwin/crypto/cryptolibsodium";
import { calculateSHA256 } from "SafeTwin/crypto/cryptolibsodium";
import { toHex } from "SafeTwin/crypto/cryptolibsodium";

// Storage
import getFile from "storage/getFile";

// UserOperations
import getPublicKey from "UserOperations/getPublicKey";
import getPubKeyUrl from "UserOperations/getPubKeyUrl";

// RecordOperations
import fetchBlockchainRecord from "RecordOperations/fetchBlockchainRecord";

// TagOperations
import getTagInfo from "TagOperations/getTagInfo";

// A ---------------------------------------------------------------------- M

const verifySignature = async (uuid, record, type) => {
  try {
    let hash;

    switch (type) {
      case "ai_data_out": {
        hash = toHex(calculateSHA256(record.data.alarm + record.data.prediction + record.data.sensor_id + record.data.timestamp + record.data.windowEnd + record.data.windowStart));
        break;
      }

      case "cfg": {
        hash = toHex(calculateSHA256(record.data.jsonconfig + record.data.target + record.data.timestamp));
        break;
      }

      case "doc":
      case "img": {
        const fileByteArray = await getFile(record.data.attachment);
        if (!fileByteArray) return null;
        const fileSHA256hex = toHex(calculateSHA256(fileByteArray));
        hash = toHex(calculateSHA256(fileSHA256hex + (type === "doc" ? record.data.comment : record.data.caption) + record.data.timestamp));
        break;
      }

      case "info": {
        hash = toHex(calculateSHA256(record.data.text + record.data.timestamp));
        break;
      }

      case "like_sirti": {
        hash = toHex(calculateSHA256(record.data.c?.transactionPayload));
        break;
      }

      case "mps": {
        hash = toHex(calculateSHA256(record.data.value + record.data.uom + record.data.timestamp));
        break;
      }

      case "sensor_data_aggregated": {
        hash = toHex(
          calculateSHA256(
            record.data.avg +
              record.data.end +
              record.data.magnitude +
              record.data.measure_unit +
              record.data.sensor_id +
              record.data.start +
              record.data.stddev +
              record.data.timestamp_end +
              record.data.timestamp_start
          )
        );
        break;
      }

      default: {
        hash = "";
      }
    }

    const { txid, creator_uuid } = record;
    const pubkey = await getPublicKey(creator_uuid);
    const pubkeyURL = await getPubKeyUrl(creator_uuid);
    const bc_record = await fetchBlockchainRecord(txid, pubkey, pubkeyURL);

    if (bc_record.error) {
      return { tx_not_found: true };
    }

    const check = compareSHA256(bc_record.txbody.hsh, hash);

    const baseResult = {
      check,
      timestamp: record.data.timestamp,
      record_hash: hash,
      pubkey,
      pubkeyURL,
      confirmed: bc_record.confirmed,
      key_source: bc_record.key_source,
      txid: bc_record.txid,
      tx_timestamp: bc_record.tx_timestamp,
      signature_status: bc_record.signature_status,
      server_id: bc_record.server_id,
      server_id_available: bc_record.server_id_available,
      hrt: bc_record.txbody.hrt,
      hsh: bc_record.txbody.hsh,
      sig: bc_record.txbody.sig,
    };

    if (bc_record.txbody.tdr) {
      const tagInfo = await getTagInfo(bc_record.txbody.tdr);
      return { ...baseResult, tagname: tagInfo?.name || "", tdr: bc_record.txbody.tdr };
    }

    return baseResult;
  } catch (error) {
    console.error(`Error in verifySignature for txid ${record.txid}:`, error.message);
    return null;
  }
};

export default verifySignature;
