// React
import React, { useEffect, memo, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { Dialog, DialogTitle, DialogContent, Typography, Box, Grid, IconButton, Paper, SwipeableDrawer, useMediaQuery, Button } from "@mui/material";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "../Puller";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const RecursiveDisplay = memo(({ data, level = 0, handleFileClick, type }) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    if (!data || typeof data !== "object") return null;

    const sortedEntries = Object.entries(data).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

    return sortedEntries.map(([key, value]) => (
      <Box
        key={key}
        sx={{
          marginBottom: 1,
          paddingLeft: level * 2,
          borderLeft: level > 0 ? "1px solid #ddd" : "none",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold", marginRight: 1 }}>
          {key.charAt(0).toUpperCase() + key.slice(1)}
        </Typography>
        {key === "attachment" ? (
          <Button variant="contained" fullWidth onClick={() => handleFileClick(value)}>
            {t("open_file")}
          </Button>
        ) : typeof value === "object" && value !== null ? (
          <RecursiveDisplay data={value} level={level + 1} handleFileClick={handleFileClick} type={type} />
        ) : (
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {value !== undefined && value !== null ? String(value) : "N/A"}
          </Typography>
        )}
      </Box>
    ));
  }, [data, level, handleFileClick, type]);

  return <>{content}</>;
});

const DataDialog = ({ data, open, onClose, handleFileClick }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        onClose();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open, onClose]);

  const typeMap = {
    info: t("note"),
    doc: t("document"),
    img: t("image"),
    mps: t("mps"),
    CDNFile: t("file"),
    sensor_data_aggregated: t("sensor_data_aggregated"),
    ai_data_out: t("ai_data_out"),
    like_sirti: t("like_sirti"),
  };

  const type = typeMap[data.type] || t("custom");

  const DetailsForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("id")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {data.id || "N/A"}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("generation_date")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {data.timestamp ? convertTimestamp(data.timestamp) : "N/A"}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("creator")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {data.creator_email || data.creator_uuid}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("type")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {type || "N/A"}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("visibility")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {data.public ? t("public") : t("private")}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {t("databox")}
            <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
              {data.databoxName || "N/A"}
            </Typography>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            {t("data")}
          </Typography>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: 2,
              maxHeight: "300px",
              overflow: "auto",
            }}
          >
            <RecursiveDisplay data={data.data || {}} handleFileClick={handleFileClick} type={data.type} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("details")}
            </Typography>
          </Grid>
        </Grid>
        {DetailsForm}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("details")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} edge="end" sx={{ color: "red" }} aria-label={t("close")}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{DetailsForm}</DialogContent>
    </Dialog>
  );
};

DataDialog.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleFileClick: PropTypes.func.isRequired,
};

export default DataDialog;
