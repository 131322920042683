// React
import React from "react";
import { Navigate } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// A ---------------------------------------------------------------------- M

const ArmilisRoute = ({ children }) => {
  const { user } = UserAuth();

  // Nightly && Live Channel
  if (user.uid === "m0tdP3x6H8Y9ILAshnEz80SvpDk1" || user.uid === "ZIVPXJ2XUsfzdTnT8EmpG6HkdwV2" || user.uid === "POjoUaJbSKQNaEPm6WiVwcTLzxI2" || user.uid === "uoeoo7bdWtP5d4ZAVQrroOLnzhU2") {
    return children;
  } else if (user.uid === "klA5kQcuCCVIIZFCGSpqbDq7oFH3" || user.uid === "sAk1YGalcQNa1GBKz9dufSWmRQ22") {
    return children;
  }

  return <Navigate to="/signin" />;
};

export default ArmilisRoute;
