// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import SendIcon from "@mui/icons-material/Send";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import { NewCertConfirm, NewRequestConfirm, InvalidFile } from "ui-components/ORFeedbacks";

// SafeTwin
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";

// ImageGenerator
import genImageRecord from "generator/ImageGenerator/genImageRecord";

// TagOperations
import sendCertificationRequest from "TagOperations/sendCertificationRequest";
import completeCertificationRequest from "TagOperations/completeCertificationRequest";

// Utils
import fileToUint8Array from "utils/fileToUint8Array";

// Storage
import uploadFile from "storage/uploadFile";
import getFile from "storage/getFile";

// A ---------------------------------------------------------------------- M

const MAX_FILE_SIZE = 25 * 1024 * 1024;

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));

const ImageGenerator = ({ tags, tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError, request, setRequest }) => {
  const { user } = UserAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [keypair, setKeyPair] = useState(null);
  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [downloadURL, setDownloadUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [caption, setCaption] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    if (request) {
      const downloadURL = request.data.downloadURL.downloadURL;
      const fileName = request.data.fileName;
      const fileType = request.data.fileType;

      const fetchFile = async () => {
        const bytearray = await getFile(downloadURL);
        setSource(bytearray);
      };

      fetchFile();
      setDownloadUrl(downloadURL);
      setFileName(fileName);
      setFileType(fileType);
      setCaption(request.data.caption);
      setIsPublic(request.public);
    } else {
      setSource("");
      setDownloadUrl("");
      setFileName("");
      setFileType("");
      setCaption("");
      setIsPublic(false);
    }
  }, [request]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        let downloadURL = "";
        let result = null;

        if (request) {
          downloadURL = request.data.downloadURL.downloadURL;
        } else {
          const uploadResult = await uploadFile(source, fileName, fileType, tag, isPublic);
          downloadURL = uploadResult.downloadURL;
        }

        if (downloadURL) {
          const dataBody = {
            source,
            downloadURL,
            fileName,
            fileType,
            caption,
          };

          if (tag) {
            result = await genImageRecord(isPublic, user.uid, keypair, dataBody, tag);
          } else if (selectedTag) {
            result = await genImageRecord(isPublic, user.uid, keypair, dataBody, selectedTag);
          } else {
            result = await genImageRecord(isPublic, user.uid, keypair, dataBody);
          }

          if (result.success) {
            if (request) {
              await completeCertificationRequest(tag, request.id);
            }

            handleOpenCertificationSuccessful();

            if (types && !types.includes("img")) {
              setTypes([...types, "img"]);
            }
          } else {
            handleOpenCertificationError();
          }
        } else {
          console.error("Invalid file format.");
          setIsInvalidFile(true);
          resetFileInput();
        }
      } catch (error) {
        console.error("Error in handleGenerate:", error.message);
        handleOpenCertificationError();
      } finally {
        setIsLoading(false);
        handleReset();
      }
    } else {
      console.log("No keypair provided.");
    }

    setIsLoading(false);
    handleReset();
  };

  const handleSendApprovalRequest = async () => {
    setApproval(false);
    setIsLoading(true);

    const downloadURL = await uploadFile(source, fileName, fileType, tag, isPublic);

    const requestData = {
      downloadURL,
      fileName,
      fileType,
      caption,
    };

    const requestTag = tag || selectedTag;

    await sendCertificationRequest("img", user.uid, isPublic, requestData, requestTag);

    setIsLoading(false);
    handleReset();
  };

  const handleCapture = async (target) => {
    if (target?.files?.length > 0) {
      const file = target.files[0];

      if (file.size > MAX_FILE_SIZE) {
        console.error("File size exceeds 25MB limit.");
        setErrorMessage("File size exceeds the 25MB limit.");
        setIsInvalidFile(true);
        resetFileInput();
        return;
      }

      const validExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (validExtensions.includes(fileExtension) && (mimeType.includes("image/png") || mimeType.includes("image/jpg") || mimeType.includes("image/jpeg"))) {
        const fileByteArray = await fileToUint8Array(file);
        const blobURL = URL.createObjectURL(file);
        setSource(fileByteArray);
        setBlobURL(blobURL);
        setFileName(file.name);
        setFileType(file.type);
      } else {
        console.error(`Invalid file format: ${fileExtension}. Allowed formats: png, jpg, jpeg.`);
        setErrorMessage(`Invalid file format: ${fileExtension}. Allowed formats: png, jpg, jpeg.`);
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("certify-image-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleReset = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setFileName("");
    setFileType("");
    setDownloadUrl("");
    setCaption("");
    setSelectedTag("");
    setIsPublic(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
    resetFileInput();
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const handleImageClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
        setSelectedImage(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const handleCloseDialogImage = () => {
    setSelectedImage(null);
  };

  const ImageReportForm = (
    <Grid container spacing={2}>
      {request ? (
        <Grid item container alignItems="center">
          <Grid item xs={12} sm={0.5}>
            <Typography>{t("file")} *</Typography>
          </Grid>
          <Grid item xs={12} sm={11.5}>
            <Button onClick={() => handleImageClick(downloadURL)} variant="contained" sx={{ width: isMobile ? "100%" : "30%" }}>
              {fileName || t("selected_file")}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={1}>
            <Typography>{t("file")} *</Typography>
          </Grid>
          <Grid item xs={12} sm={11}>
            <input className={classes.input} id="certify-image-button" type="file" accept="image/png, image/jpg, image/jpeg" disabled={request} onChange={(e) => handleCapture(e.target)} />
            <label htmlFor="certify-image-button">
              <Button disabled={request} variant="contained" component="span" sx={{ width: isMobile ? "100%" : "30%" }} startIcon={<FileUploadIcon />}>
                {t("select_file")}
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Typography>{t("selected_file")}</Typography>
          </Grid>
          {source ? (
            <Grid item xs={12} sm={10.5}>
              <Button onClick={() => handleImageClick(blobURL)} variant="contained" sx={{ width: isMobile ? "100%" : "30%" }}>
                {fileName || t("selected_file")}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} sm={10.5}>
              <Typography>{t("no_file_selected")}</Typography>
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField fullWidth margin="dense" id="Caption" name="Caption" label={t("description")} variant="outlined" value={caption} disabled={request} onChange={(e) => setCaption(e.target.value)} />
      </Grid>
      {location.pathname === "/my-certifications" && tags && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t("databox")}</Typography>
          <FormControl fullWidth>
            <Select
              id="selected-tag-image"
              value={selectedTag}
              displayEmpty
              onChange={handleChange}
              open={tagSelectionOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <MenuItem value="">
                <Typography color="gray">{t("no_databox")}</Typography>
              </MenuItem>
              {tags
                .sort((a, b) => a.nickname.localeCompare(b.nickname))
                .map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.nickname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublic} disabled={request} onChange={(event) => setIsPublic(event.target.checked)} inputProps={{ "aria-label": "controlled" }} />}
            label={t("mark_as_public")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "70%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                {t("certify_image")}
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {ImageReportForm}
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" disabled={isLoading || !source} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                    {t("certify")}
                  </Button>
                </Grid>
                {!request && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isLoading || (location.pathname === "/my-certifications" && (!source || !selectedTag)) || (location.pathname !== "/my-certifications" && !source)}
                      onClick={() => setApproval(true)}
                      startIcon={<SendIcon />}
                    >
                      {t("send_request")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestConfirm approval={approval} setApproval={setApproval} handleSendApprovalRequest={handleSendApprovalRequest} />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} message={errorMessage} />
      {selectedImage && (
        <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage} fullWidth maxWidth="md">
          <IconButton
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              zIndex: 1,
              color: "red",
            }}
            onClick={handleCloseDialogImage}
          >
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
          <img src={selectedImage} alt="img" width={"100%"} height={300} />
        </Dialog>
      )}
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_image")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading ? (
          <LinearLoadingComponent />
        ) : (
          <DialogContent dividers>
            {ImageReportForm}
            <Grid item container xs={12} spacing={1} mt="3%">
              <Grid item xs={12} sm={!request ? 6 : 12}>
                <Button fullWidth variant="contained" disabled={isLoading || !source} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                  {t("certify")}
                </Button>
              </Grid>
              {!request && (
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || (location.pathname === "/my-certifications" && (!source || !selectedTag)) || (location.pathname !== "/my-certifications" && !source)}
                    onClick={() => setApproval(true)}
                    startIcon={<SendIcon />}
                  >
                    {t("send_request")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        )}
      </Dialog>
      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestConfirm approval={approval} setApproval={setApproval} handleSendApprovalRequest={handleSendApprovalRequest} />
      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} message={errorMessage} />
      <Dialog open={Boolean(selectedImage)} onClose={handleCloseDialogImage} fullWidth maxWidth="lg">
        <IconButton
          sx={{
            position: "absolute",
            top: "2%",
            right: "2%",
            zIndex: 1,
            color: "red",
          }}
          onClick={handleCloseDialogImage}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <img src={selectedImage} alt="img" />
      </Dialog>
    </>
  );
};

export default ImageGenerator;
