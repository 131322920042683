const sensorColorMap = {
  DR_1: "rgb(255, 99, 132)", // Red
  DR_2: "rgb(54, 162, 235)", // Blue
  DR_3: "rgb(255, 206, 86)", // Yellow
  DR_4: "rgb(75, 192, 192)", // Teal
  DR_5: "rgb(153, 102, 255)", // Purple
  DR_6: "rgb(255, 159, 64)", // Orange
  DR_7: "rgb(199, 199, 199)", // Grey
  DR_8: "rgb(255, 0, 0)", // Bright Red
  DR_9: "rgb(0, 128, 0)", // Green
  DR_10: "rgb(0, 0, 255)", // Bright Blue
  DR_11: "rgb(255, 20, 147)", // Deep Pink
  DR_12: "rgb(128, 0, 0)", // Maroon
  DR_13: "rgb(0, 128, 128)", // Teal
  DR_14: "rgb(148, 0, 211)", // Dark Violet
  DR_15: "rgb(70, 130, 180)", // Steel Blue
  DR_16: "rgb(210, 105, 30)", // Chocolate
  DR_17: "rgb(255, 0, 255)", // Magenta
  DR_18: "rgb(0, 255, 0)", // Lime
  DR_19: "rgb(34, 139, 34)", // Forest Green
  DR_20: "rgb(218, 165, 32)", // Goldenrod
  DR_21: "rgb(128, 128, 0)", // Olive
  DR_22: "rgb(255, 165, 0)", // Orange
  DR_23: "rgb(0, 255, 127)", // Spring Green
  DR_24: "rgb(0, 255, 255)", // Cyan
  DR_25: "rgb(255, 105, 180)", // Hot Pink
  DR_26: "rgb(127, 255, 0)", // Chartreuse
  RPM_1: "rgb(255, 69, 0)", // Red-Orange
  RPM_2: "rgb(123, 104, 238)", // Medium Slate Blue
};

export default sensorColorMap;
