// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addMemberToGroup = async (newMemberUID, groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const usersRef = collection(groupRef, "users");
    const userRef = doc(usersRef, newMemberUID);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      return { status: "user_already_added" };
    }

    await setDoc(userRef, {
      uid: newMemberUID,
      added_on: unixTimestampInSeconds(),
    });

    await updateDoc(groupRef, {
      members: group.data().members + 1,
    });

    return { status: "success", documentId: userRef.id };
  } catch (error) {
    console.error("Error in addMemberToGroup:", error);
    return { status: "error", message: error.message };
  }
};

export default addMemberToGroup;
