// Firebase
import { db } from "config/firebase";
import { doc, collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getActivatedPublicKeys = async (uuid) => {
  try {
    const userRef = doc(db, "enabled_records", uuid);
    const collectionRef = collection(userRef, "enabled_public_keys");
    const snapshot = await getDocs(collectionRef);

    const publicKeysData = snapshot.docs.map((doc) => ({
      ...doc.data(),
    }));

    return publicKeysData;
  } catch (error) {
    console.error(`Error in getActivatedPublicKeys for user ${uuid}:`, error.message);
    return [];
  }
};

export default getActivatedPublicKeys;
