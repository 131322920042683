// React
import React from "react";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// Material UI Components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Tooltip from "@mui/material/Tooltip";

// Material UI Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

// A ---------------------------------------------------------------------- M

const Target = ({ data }) => {
  return (
    data && (
      <Card
        variant="outlined"
        sx={{
          width: "800px",
          p: 3,
          borderRadius: "8px", // More rounded for modern feel
          backgroundColor: "rgba(38, 50, 56, 0.9)", // Slightly darker gray
          backgroundImage: "linear-gradient(135deg, #263238 0%, #37474f 100%)",
          border: "1px solid rgba(255, 255, 255, 0.1)", // Subtle white border
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)", // Deeper shadow for immersion
          color: "#ffffff", // Light text for contrast
          overflow: "hidden", // Prevent content overflow
        }}
      >
        {/* Title */}
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          fontWeight="bold"
          sx={{
            color: "#ffffff",
            borderBottom: "2px solid #4caf50", // Green accent
            pb: 1,
            letterSpacing: "1px",
          }}
        >
          Target
        </Typography>

        <Table size="small" sx={{ backgroundColor: "transparent" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5", // Light gray
                    letterSpacing: "0.5px",
                  }}
                >
                  Endpoint
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Type
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Data Types
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    textTransform: "uppercase",
                    color: "#b0bec5",
                    letterSpacing: "0.5px",
                  }}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: data.target.enabled ? "#263238" : "#37474f", // Alternating row colors
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.1)", // Highlight on hover
                  cursor: "pointer",
                },
              }}
            >
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.endpoint || "N/A"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.type || "REST"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {data.target.types?.length > 0 ? data.target.types.join(", ") : "N/A"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={data.target.enabled ? "Enabled" : "Disabled"} arrow>
                  {data.target.enabled ? (
                    <CheckCircleOutlineIcon
                      sx={{
                        color: "#4caf50",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <CancelIcon
                      sx={{
                        color: "#f44336",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        {/* ReactFlow Handle */}
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-${data.target.endpoint}`}
          style={{
            background: "#4caf50", // Green to match theme
            border: "2px solid #ffffff",
            width: "12px",
            height: "12px",
          }}
        />
      </Card>
    )
  );
};

export default Target;
