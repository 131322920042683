// React
import React, { useState, useEffect, forwardRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Drawer from "@mui/material/Drawer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";

// Material UI Icons
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineIcon from "@mui/icons-material/Timeline";
import NoteIcon from "@mui/icons-material/Note";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import SensorsIcon from "@mui/icons-material/Sensors";
import CustomIcon from "@mui/icons-material/Category";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

// Components
import TagToolbarPublic from "ui-components/DataboxManagement/TagToolbarPublic";
import AggregatedViewer from "ui-components/DataTypeManagement/History/AggregatedViewer";
import DocViewer from "ui-components/DataTypeManagement/Doc/DocViewer";
import ImageViewer from "ui-components/DataTypeManagement/Image/ImageViewer";
import InfoViewer from "ui-components/DataTypeManagement/Info/InfoViewer";
import ConfigViewer from "ui-components/DataTypeManagement/Config/ConfigViewer";
import MpsViewer from "ui-components/DataTypeManagement/Mps/MpsViewer";

// TagOperations
import getTagTypes from "TagOperations/getTagTypes";
import getTags from "TagOperations/getTags";
import getTagInfo from "TagOperations/getTagInfo";

// A ---------------------------------------------------------------------- M

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box mt={2}>{children}</Box>}
    </div>
  );
};

const PublicTag = () => {
  const { tag } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [types, setTypes] = useState([]);
  const [tagExist, setTagExist] = useState(false);
  const [tagNotExist, setTagNotExist] = useState(false);
  const [tagInformation, setTagInformation] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handlePopstate = () => {
      if (tagNotExist) {
        handleTagNotExist();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [tagNotExist]);

  useEffect(() => {
    const fetchTagInfo = async () => {
      const tagInformation = await getTagInfo(tag);
      setTagInformation(tagInformation);
    };

    const getTypes = async () => {
      const types = await getTagTypes(tag);
      const allTypes = [...new Set([...types, ...types])];
      setTypes(allTypes);
    };

    const tagFlow = async () => {
      const tagIDs = await getTags();
      if (tagIDs.includes(tag)) {
        setTagExist(true);
        getTypes();
      } else {
        handleTagNotExistDrawer();
      }
    };

    const fetchData = async () => {
      await fetchTagInfo();
      await tagFlow();
      setIsLoading(false);
    };

    fetchData();
  }, [tag]);

  const handleTagNotExistDrawer = () => {
    setTagNotExist(true);
    window.history.pushState(null, "");
  };

  const handleTagNotExist = () => {
    setTagNotExist(false);
    navigate("/signin");
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [value1, setValue1] = useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={tagNotExist}
          sx={{ "& .MuiDrawer-paper": { width: "100%" } }}
          PaperProps={{
            sx: { borderTopLeftRadius: "4%", borderTopRightRadius: "4%" },
          }}
        >
          <Box sx={{ p: "6%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SearchOffOutlinedIcon fontSize="large" sx={{ color: "red" }} />
            </Box>
            <Typography variant="h5" color="red" align="center" gutterBottom>
              Not Found
            </Typography>
            <Typography variant="body1" color="red" align="center" gutterBottom>
              DataboxID: <b>{tag}</b> does not exist.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
              <Button variant="contained" onClick={handleTagNotExist}>
                Close
              </Button>
            </Box>
          </Box>
        </Drawer>
      ) : (
        <Snackbar open={tagNotExist} autoHideDuration={3000} onClose={handleTagNotExist} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{ mt: { xs: "20%", md: "5%" } }}>
          <Alert onClose={handleTagNotExist} severity="error" sx={{ width: "100%" }}>
            Databox: <b>{tag}</b> does not exist.
          </Alert>
        </Snackbar>
      )}

      {tagExist && (
        <>
          <Grid item container xs={12} spacing={5}>
            {tagInformation && (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TagToolbarPublic tag={tagInformation} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}

            <Grid item container xs={12} spacing={2}>
              <Grid item container alignItems="center">
                <Grid item xs={12} sm={10}>
                  <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold">
                    {t("certifications")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container xs={12} spacing={3}>
                {(types.includes("cfg") || types.includes("mps")) && (
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginBottom: 1,
                        backgroundColor: "grey.100",
                        boxShadow: 1,
                      }}
                    >
                      <Tabs value={value1} onChange={handleChange1} textColor="primary" indicatorColor="primary" sx={{ "& .MuiTab-root": { fontWeight: "bold" } }}>
                        {["cfg", "mps"]
                          .filter((type) => types.includes(type))
                          .sort()
                          .map((type) => (
                            <Tab
                              key={type}
                              icon={type === "cfg" ? <SettingsIcon fontSize="large" /> : <TimelineIcon fontSize="large" />}
                              label={
                                isMobile ? null : (
                                  <Typography variant="body1" color="primary">
                                    {type === "cfg" ? t("configurations") : t("stream")}
                                  </Typography>
                                )
                              }
                            />
                          ))}
                      </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                      {["cfg", "mps"]
                        .filter((type) => types.includes(type))
                        .sort()
                        .map((type, index) => (
                          <TabPanel key={index} value={value1} index={index}>
                            {type === "cfg" ? (
                              <ConfigViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />
                            ) : (
                              <MpsViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />
                            )}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}

                {(types.includes("doc") || types.includes("img") || types.includes("info")) && (
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent="flex-end"
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        marginBottom: 1,
                        backgroundColor: "grey.100",
                        boxShadow: 1,
                      }}
                    >
                      <Tabs value={value} onChange={handleChange} textColor="primary" indicatorColor="primary" sx={{ "& .MuiTab-root": { fontWeight: "bold" } }}>
                        {types.includes("doc") || types.includes("img") || types.includes("info") ? (
                          <Tab
                            icon={<AccessTimeIcon fontSize="large" />}
                            label={
                              isMobile ? null : (
                                <Typography variant="body1" color="primary">
                                  {t("history")}
                                </Typography>
                              )
                            }
                          />
                        ) : null}
                        {["doc", "img", "info"]
                          .filter((type) => types.includes(type))
                          .sort((a, b) => {
                            const order = ["doc", "img", "info"];
                            return order.indexOf(a) - order.indexOf(b);
                          })
                          .map((type) => {
                            const icon = {
                              doc: <DescriptionIcon fontSize="large" />,
                              img: <ImageIcon fontSize="large" />,
                              info: <NoteIcon fontSize="large" />,
                              like_sirti: <CustomIcon fontSize="large" />,
                              source_telemetrics: <SensorsIcon fontSize="large" />,
                            }[type];

                            const label = {
                              info: t("notes"),
                              doc: t("documents"),
                              img: t("images"),
                              like_sirti: "like_sirti",
                              source_telemetrics: "source_telemetrics",
                            }[type];
                            return (
                              <Tab
                                key={type}
                                icon={icon}
                                label={
                                  isMobile ? null : (
                                    <Typography variant="body1" color="primary">
                                      {label}
                                    </Typography>
                                  )
                                }
                              />
                            );
                          })}
                      </Tabs>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <AggregatedViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />
                      </TabPanel>
                      {["doc", "img", "info"]
                        .filter((type) => types.includes(type))
                        .sort((a, b) => {
                          const order = ["doc", "img", "info"];
                          return order.indexOf(a) - order.indexOf(b);
                        })
                        .map((type, index) => (
                          <TabPanel key={type} value={value} index={index + 1}>
                            {type === "doc" && <DocViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />}
                            {type === "img" && <ImageViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />}
                            {type === "info" && <InfoViewer tag={{ id: tagInformation.id, name: tagInformation.name }} isTagGroupMember={false} />}
                          </TabPanel>
                        ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default PublicTag;
