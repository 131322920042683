// React
import React from "react";

// @react-pdf/renderer
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from "@react-pdf/renderer";

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DownloadIcon from "@mui/icons-material/Download";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: "#ffffff",
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: "1pt solid #e0e0e0",
    borderRadius: 5,
  },
  successIcon: {
    color: "green",
    fontSize: 16,
    marginRight: 5,
  },
  errorIcon: {
    color: "red",
    fontSize: 16,
    marginRight: 5,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  subheading: {
    fontSize: 14,
    fontWeight: "bold",
    color: "green",
    marginBottom: 5,
  },
  errorSubheading: {
    fontSize: 14,
    fontWeight: "bold",
    color: "red",
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  strong: {
    fontWeight: "bold",
  },
});

const PDFReceiptComponent = ({ uploadReceipt }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.title}>Rapporto Di Versamento</Text>
      </View>
      {uploadReceipt.status === "valid" ? (
        <>
          <View style={styles.section}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={styles.successIcon}>✔</Text>
              <Text>
                Il documento è stato versato con successo. L'identificativo assegnato è: <Text style={styles.strong}>{uploadReceipt.txid || "N/A"}</Text>
              </Text>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.subheading}>Dettagli Versamento</Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Effettuato da: </Text>
              {uploadReceipt.creatorEmail || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Stato versamento: </Text>
              {uploadReceipt.status || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Nome documento: </Text>
              {uploadReceipt.fileName || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Tipo documento: </Text>
              {uploadReceipt.fileType || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Datetime generazione rapporto di versamento: </Text>
              {uploadReceipt.timestamp || "N/A"}
            </Text>
          </View>
        </>
      ) : (
        <>
          <View style={styles.section}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={styles.errorIcon}>✖</Text>
              <Text>Errore nel versamento del documento.</Text>
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.errorSubheading}>Dettagli Rifiuto Di Versamento</Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Versamento Effettuato Da: </Text>
              {uploadReceipt.creatorEmail || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Stato Versamento: </Text>
              {uploadReceipt.status || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Nome Documento Rifiutato: </Text>
              {uploadReceipt.fileName || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Tipo Documento Rifiutato: </Text>
              {uploadReceipt.fileType || "N/A"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.strong}>Datetime Rifiuto Versamento: </Text>
              {uploadReceipt.timestamp || "N/A"}
            </Text>
          </View>
        </>
      )}
    </Page>
  </Document>
);

const CDNFileUploadReceipt = ({ receiptDialogOpen, handleResetUploadReceipt, uploadReceipt }) => {
  return (
    <Dialog open={receiptDialogOpen} onClose={handleResetUploadReceipt} fullWidth maxWidth="lg">
      <Box sx={{ p: "2%" }}>
        <Grid container alignItems="center" justifyContent="space-between" mb="3%">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              Rapporto Di Versamento
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleResetUploadReceipt} sx={{ color: "red" }} edge="end">
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item container spacing={3}>
            {uploadReceipt.status === "valid" ? (
              <>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: "2%" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={0.5}>
                        <CheckCircleIcon sx={{ color: "green" }} fontSize="large" />
                      </Grid>
                      <Grid item xs={11.5}>
                        <Typography>
                          Il documento è stato versato con successo. L'identificativo assegnato è: <b>{uploadReceipt.txid || "N/A"}</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={10} sx={{ p: "2%" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography color="green" gutterBottom fontWeight="bold">
                          Dettagli Versamento
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Effettuato da:</strong> {uploadReceipt.creatorEmail || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Stato versamento:</strong> {uploadReceipt.status || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Nome documento:</strong> {uploadReceipt.fileName || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Tipo documento:</strong> {uploadReceipt.fileType || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Datetime generazione rapporto di versamento:</strong> {uploadReceipt.timestamp ? convertTimestamp(uploadReceipt.timestamp) : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Paper elevation={4} sx={{ p: "2%" }}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={0.5}>
                        <ErrorOutlineIcon sx={{ color: "red" }} fontSize="large" />
                      </Grid>
                      <Grid item xs={11.5}>
                        <Typography color="error">Errore nel versamento del documento.</Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper elevation={10} sx={{ p: "2%" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography color="error" gutterBottom>
                          Dettagli Rifiuto Di Versamento
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Versamento Effettuato Da:</strong> {uploadReceipt.creatorEmail || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Stato Versamento:</strong> {uploadReceipt.status || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Nome Documento Rifiutato:</strong> {uploadReceipt.fileName || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Tipo Documento Rifiutato:</strong> {uploadReceipt.fileType || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Datetime Rifiuto Versamento:</strong> {uploadReceipt.timestamp ? convertTimestamp(uploadReceipt.timestamp) : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container justifyContent="flex-end">
            <PDFDownloadLink document={<PDFReceiptComponent uploadReceipt={uploadReceipt} />} fileName="submission_report.pdf" style={{ textDecoration: "none" }}>
              {({ loading }) => (
                <Button variant="contained" startIcon={<DownloadIcon />}>
                  {loading ? "Generating PDF..." : "Export As PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CDNFileUploadReceipt;
