// Firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs, getDoc, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getDataKeysByOwner = async (uuid) => {
  try {
    const keyDataCollectionRef = collection(db, "keydata");
    const q = query(keyDataCollectionRef, where("owner_uuid", "==", uuid));
    const snapshot = await getDocs(q);

    const keysPromises = snapshot.docs.map(async (keyDoc) => {
      const keySecretDocRef = doc(db, "keysecrets", keyDoc.id);
      const keySecretSnapshot = await getDoc(keySecretDocRef);

      const keySecretData = keySecretSnapshot.exists() ? keySecretSnapshot.data() : {};
      const { name = "", created_on = "" } = keyDoc.data();

      return {
        id: keyDoc.id,
        name,
        created_on,
        datakey: keySecretData.datakey || "",
        canCreateDatabox: keySecretData.canCreateDatabox || false,
      };
    });

    const keys = await Promise.all(keysPromises);
    return keys;
  } catch (error) {
    console.error(`Error in getDataKeysByOwner for uuid ${uuid}:`, error.message);
    return [];
  }
};

export default getDataKeysByOwner;
