// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import SendIcon from "@mui/icons-material/Send";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import { NewCertConfirm, NewRequestConfirm } from "ui-components/ORFeedbacks";

// SafeTwin
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";

// InforGenerator
import genInfoRecord from "generator/InfoGenerator/genInfoRecord";

// TagOperations
import sendCertificationRequest from "TagOperations/sendCertificationRequest";
import completeCertificationRequest from "TagOperations/completeCertificationRequest";

// A ---------------------------------------------------------------------- M

const InfoGenerator = ({ tags, tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError, request, setRequest }) => {
  const { user } = UserAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [keypair, setKeyPair] = useState(null);
  const [text, setText] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    if (request) {
      setText(request.data.text);
      setIsPublic(request.public);
    } else {
      setText("");
      setIsPublic(false);
    }
  }, [request]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        let result = null;

        const dataBody = {
          text,
        };

        if (tag) {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody, tag);
        } else if (selectedTag) {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody, selectedTag);
        } else {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody);
        }

        if (result.success) {
          if (request) {
            await completeCertificationRequest(tag, request.id);
          }

          handleOpenCertificationSuccessful();

          if (types && !types.includes("info")) {
            setTypes([...types, "info"]);
          }
        } else {
          handleOpenCertificationError();
        }
      } catch (error) {
        console.error("Error in handleGenerate:", error.message);
        handleOpenCertificationError();
      } finally {
        setIsLoading(false);
        handleReset();
      }
    } else {
      console.log("No keypair provided.");
    }

    setIsLoading(false);
    handleReset();
  };

  const handleSendApprovalRequest = async () => {
    setApproval(false);
    setIsLoading(true);

    const requestData = {
      text,
    };

    const requestTag = tag || selectedTag;

    await sendCertificationRequest("info", user.uid, isPublic, requestData, requestTag);

    setIsLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setText("");
    setSelectedTag("");
    setIsPublic(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const InfoReportForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth margin="dense" id="Text" name="Text" label={t("text")} variant="outlined" required value={text} disabled={request} onChange={(e) => setText(e.target.value)} />
      </Grid>
      {location.pathname === "/my-certifications" && tags && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t("databox")}</Typography>
          <FormControl fullWidth>
            <Select
              id="selected-tag-info"
              value={selectedTag}
              displayEmpty
              onChange={handleChange}
              open={tagSelectionOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <MenuItem value="">
                <Typography color="gray">{t("no_databox")}</Typography>
              </MenuItem>
              {tags
                .sort((a, b) => a.nickname.localeCompare(b.nickname))
                .map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.nickname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublic} disabled={request} onChange={(event) => setIsPublic(event.target.checked)} inputProps={{ "aria-label": "controlled" }} />}
            label={t("mark_as_public")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleReset}
        onOpen={() => setOpen(true)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "70%",
            borderTopLeftRadius: "4%",
            borderTopRightRadius: "4%",
          },
        }}
      >
        <Puller />
        <Box sx={{ p: "5%" }}>
          <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                {t("certify_note")}
              </Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {InfoReportForm}
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" disabled={isLoading || !text} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                    {t("certify")}
                  </Button>
                </Grid>
                {!request && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={isLoading || (location.pathname === "/my-certifications" && (!text || !selectedTag)) || (location.pathname !== "/my-certifications" && !text)}
                      onClick={() => setApproval(true)}
                      startIcon={<SendIcon />}
                    >
                      {t("send_request")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Box>
      </SwipeableDrawer>
      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestConfirm approval={approval} setApproval={setApproval} handleSendApprovalRequest={handleSendApprovalRequest} />
    </>
  ) : (
    <>
      <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" fontWeight="bold">
                {t("certify_note")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading ? (
          <LinearLoadingComponent />
        ) : (
          <DialogContent dividers>
            {InfoReportForm}
            <Grid item container xs={12} spacing={1} mt="3%">
              <Grid item xs={12} sm={!request ? 6 : 12}>
                <Button fullWidth variant="contained" disabled={isLoading || !text} onClick={() => setConfirm(true)} startIcon={<VerifiedIcon />}>
                  {t("certify")}
                </Button>
              </Grid>
              {!request && (
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={isLoading || (location.pathname === "/my-certifications" && (!text || !selectedTag)) || (location.pathname !== "/my-certifications" && !text)}
                    onClick={() => setApproval(true)}
                    startIcon={<SendIcon />}
                  >
                    {t("send_request")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        )}
      </Dialog>
      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestConfirm approval={approval} setApproval={setApproval} handleSendApprovalRequest={handleSendApprovalRequest} />
    </>
  );
};

export default InfoGenerator;
