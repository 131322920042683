// React
import React, { useState } from "react";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// Material UI Component
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

// Components
import DataboxLogModal from "./DataboxLogModal";

// A ---------------------------------------------------------------------- M

const Databox = ({ data }) => {
  const [isLogOpen, setIsLogOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleNodeClick = () => {
    setIsLogOpen(true);
  };

  const handleCloseLog = () => {
    setIsLogOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const isActive = isLogOpen || isHovered;

  return (
    <>
      <Card
        onClick={handleNodeClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        variant="outlined"
        sx={{
          width: "600px",
          p: 4,
          borderRadius: "12px",
          backgroundColor: isActive ? "rgba(48, 63, 72, 1)" : "rgba(48, 63, 72, 0.95)", // Slightly darker when active
          backgroundImage: "linear-gradient(135deg, #1e272e 0%, #3a3f44 100%)",
          border: `2px solid ${isActive ? "#4caf50" : "rgba(255, 255, 255, 0.2)"}`, // Glowing green border when active
          boxShadow: isActive
            ? "0 0 20px rgba(76, 175, 80, 0.8), 0 4px 15px rgba(0, 0, 0, 0.4)" // Strong glow when active
            : "0 4px 15px rgba(0, 0, 0, 0.4)",
          textAlign: "center",
          color: "#ffffff",
          position: "relative",
          cursor: "pointer",
          transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
        }}
      >
        {/* Databox Title */}
        <Typography
          variant="h5"
          gutterBottom
          fontWeight="bold"
          sx={{
            color: "#4caf50", // Green accent to highlight importance
            letterSpacing: "1.5px",
            textTransform: "uppercase",
            borderBottom: "2px solid #4caf50", // Matching green underline
            pb: 2,
          }}
        >
          {data.databoxName}
        </Typography>

        {/* ReactFlow Handles */}
        <Handle
          type="target"
          position={Position.Left}
          id="databox-users-target"
          style={{
            top: "30%", // Position for user connections
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />
        <Handle
          type="target"
          position={Position.Left}
          id="databox-keys-target"
          style={{
            top: "70%", // Position for keys connections
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />
        <Handle
          type="source"
          position={Position.Right}
          id="e"
          style={{
            top: "50%", // Central source position
            background: "#4caf50",
            border: "2px solid #ffffff",
            width: "14px",
            height: "14px",
          }}
        />
      </Card>

      {/* Databox Log Modal */}
      {isLogOpen && <DataboxLogModal isOpen={isLogOpen} onClose={handleCloseLog} databoxID={data.databoxID} />}
    </>
  );
};

export default Databox;
