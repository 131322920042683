const convertTimestampDate = (timestamp) => {
  try {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  } catch (e) {
    console.error("convert timestamp failed: ", e.message);
    return "N/A";
  }
};

export default convertTimestampDate;
