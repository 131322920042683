// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addSignature = async (uid, txid, timestamp, type, tdr) => {
  try {
    const userRef = doc(db, "userdata", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      await setDoc(userRef, {
        admin: false,
      });
    }

    const mysigsRef = collection(userRef, "mysigs");

    await setDoc(doc(mysigsRef, txid), {
      timestamp,
      type,
      ...(tdr && { tdr }),
    });
    console.log(`Signature with txid ${txid} added for user ${uid}.`);
  } catch (error) {
    console.error(`Add signature failed for user ${uid} and txid ${txid}:`, error.message);
  }
};

export default addSignature;
