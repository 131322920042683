// Firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getUUIDFromEmail = async (new_member_email) => {
  try {
    const q = query(collection(db, "usercontacts"), where("email", "==", new_member_email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    return querySnapshot.docs[0].id;
  } catch (error) {
    console.error(`Error in getUUIDFromEmail for email ${new_member_email}:`, error.message);
    return null;
  }
};

export default getUUIDFromEmail;
