// React
import React, { useState, useMemo } from "react";

// Prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Pending as PendingIcon,
  TaskAlt as TaskAltIcon,
} from "@mui/icons-material";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const RequestsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "requester", label: t("requester") },
      { id: "timestamp", label: t("generation_date") },
      { id: "type", label: t("type") },
      { id: "status", label: t("status") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "status" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const RequestsTableToolbar = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={5}>
        <Typography variant="h5" fontWeight="bold">
          {t("requests")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const RequestsTable = ({ requests, handleCertify }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requests.length) : 0;

  return (
    <Box>
      <RequestsTableToolbar />
      <br />
      <Divider />
      <br />
      <TableContainer>
        <Table size="small" data-testid="requests-table">
          {!isMobile && <RequestsTableHead />}
          <TableBody>
            {(rowsPerPage > 0 ? requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : requests).map((request) => {
              const typeMap = {
                img: t("image"),
                doc: t("document"),
                info: t("note"),
                CDNFile: t("file"),
              };
              const type = typeMap[request.type] || t("custom");
              return (
                <TableRow
                  key={request.id}
                  sx={{
                    "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#bbdefb" },
                  }}
                >
                  <TableCell align="left">{request.creator_email || "N/A"}</TableCell>
                  <TableCell align="left">{request.timestamp ? convertTimestamp(request.timestamp) : "N/A"}</TableCell>
                  <TableCell align="left">{type}</TableCell>
                  <TableCell align="right">
                    {!request.completed ? (
                      <Tooltip title={t("certify")} placement="top">
                        <IconButton sx={{ color: "orange" }} onClick={() => handleCertify(request)}>
                          <PendingIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("certified")} placement="top">
                        <IconButton sx={{ color: "green" }}>
                          <TaskAltIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={requests.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

RequestsTable.propTypes = {
  requests: PropTypes.array.isRequired,
  handleCertify: PropTypes.func.isRequired,
};

export default RequestsTable;
