// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const isUserAuthorizedForDataBox = async (tagID, userID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnap = await getDoc(tagDocRef);

    if (tagDocSnap.exists() && tagDocSnap.data().owner_uuid === userID) {
      return true;
    }

    const userPermissionRef = doc(db, `tagpermissions/${tagID}/authorized_users/${userID}`);
    const userPermissionSnap = await getDoc(userPermissionRef);

    return userPermissionSnap.exists();
  } catch (error) {
    console.error(`Error checking user authorization for databox [tagID: ${tagID}, userID: ${userID}]:`, error.message);
    return false;
  }
};

export default isUserAuthorizedForDataBox;
