// Firebase
import { db } from "config/firebase";
import { collection, getDocs } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTags = async () => {
  try {
    const snapshot = await getDocs(collection(db, "tagsdata"));
    const tagIDs = snapshot.docs.map((doc) => doc.id);

    return tagIDs;
  } catch (error) {
    console.error("Error in getTags:", error.message);
    return [];
  }
};

export default getTags;
