// Firebase
import { db } from "config/firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const activatePublicKey = async (uuid, data) => {
  if (!data.publicKey) {
    console.error("publicKey is required in the data object.");
  }

  try {
    const userRef = doc(db, "enabled_records", uuid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      await setDoc(userRef, {});
    }

    const enabledPublicKeysRef = collection(userRef, "enabled_public_keys");

    const keyData = {
      ...data,
      added_on: unixTimestampInSeconds(),
    };

    await setDoc(doc(enabledPublicKeysRef, data.publicKey), keyData);

    return {
      success: true,
      publicKey: data.publicKey,
    };
  } catch (error) {
    console.error("Error in activatePublicKey:", error.message);

    return {
      success: false,
      message: `Error in activatePublicKey: ${error.message}`,
    };
  }
};

export default activatePublicKey;
