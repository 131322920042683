// Firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const createDataBox = async (tagID, uid, name, tipologiaDocumentale) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);

    const dataBox = {
      owner_uuid: uid,
      name,
      types: [],
      followers: [],
      ...(tipologiaDocumentale && { tipologiaDocumentale }),
    };

    await setDoc(tagRef, dataBox);
  } catch (error) {
    console.error(`Error in createDataBox for tagID ${tagID} and uuid ${uid}:`, error.message);
  }
};

export default createDataBox;
