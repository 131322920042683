// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

// A ---------------------------------------------------------------------- M

const Copyright = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body2" color="black" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://armilis.com/">
          Armilis
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" color="black" align="center">
        {t("all_rights_reserved")}
      </Typography>
      <Typography variant="body2" color="black" align="center">
        v{process.env.REACT_APP_RELEASE_VERSION}
      </Typography>
      {process.env.REACT_APP_ENVIRONMENT_URL === "https://nightly.twins.armilis.com" && (
        <Typography variant="body2" color="black" align="center" sx={{ fontStyle: "italic" }}>
          Nightly Channel
        </Typography>
      )}
    </>
  );
};

export default Copyright;
