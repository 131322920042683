// React
import React, { useState, useEffect, useCallback } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";

// Material UI Icons
import AddIcon from "@mui/icons-material/Add";

// Components
import { GroupCreationSuccessfulSnackbar, GroupCreationErrorSnackbar } from "ui-components/ORFeedbacks";
import GroupsTable from "ui-components/GroupManagement/GroupsTable";
import GroupGenerator from "ui-components/GroupManagement/GroupGenerator";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, query, where, collectionGroup } from "firebase/firestore";

// GroupOperations
import fetchUserGroups from "GroupOperations/fetchUserGroups";

// A ---------------------------------------------------------------------- M

const MyGroups = () => {
  const { user, canCreateGroup, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [groups, setGroups] = useState([]);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openGroupCreationSuccessful, setOpenGroupCreationSuccessful] = useState(false);
  const [openGroupCreationError, setOpenGroupCreationError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getGroups = useCallback(async () => {
    console.log("fetching groups...");
    if (user) {
      try {
        setIsLoading(true);
        const groups = await fetchUserGroups(user.uid);
        groups.sort((group1, group2) => group2.added_on - group1.added_on);
        setGroups(groups);
      } catch (error) {
        console.error("Error fetching user groups:", error.message);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid) {
      getGroups();

      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

      const newAdditionsQueryGroups = query(collectionGroup(db, "users"), where("uid", "==", user.uid), where("added_on", ">=", currentTimestampInSeconds.toString()));
      const newAdditionsUnsubscribeGroups = onSnapshot(newAdditionsQueryGroups, (snapshot) => {
        const addedGroups = snapshot
          .docChanges()
          .filter((change) => change.type === "added")
          .map((change) => change.doc.data());
        if (addedGroups.length > 0) {
          setGroups((prevGroups) => [...addedGroups, ...prevGroups].sort((group1, group2) => group2.added_on - group1.added_on));
        }
      });

      const deletionQueryGroups = query(collectionGroup(db, "users"), where("uid", "==", user.uid));
      const deletionUnsubscribeGroups = onSnapshot(deletionQueryGroups, (snapshot) => {
        const removedGroupIds = snapshot
          .docChanges()
          .filter((change) => change.type === "removed")
          .map((change) => change.doc.id);
        if (removedGroupIds.length > 0) {
          setGroups((prevGroups) => prevGroups.filter((group) => !removedGroupIds.includes(group.id)));
        }
      });

      return () => {
        newAdditionsUnsubscribeGroups();
        deletionUnsubscribeGroups();
      };
    }
  }, [user, getGroups]);

  const handleOpenGroupGenerator = () => {
    setOpenCreateGroup(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupCreationSuccessful = () => {
    setOpenGroupCreationSuccessful(true);
    window.history.pushState(null, "");
  };

  const handleOpenGroupCreationError = () => {
    setOpenGroupCreationError(true);
    window.history.pushState(null, "");
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {groups && <GroupsTable groups={groups} handleOpenGroupGenerator={handleOpenGroupGenerator} />}

          {(canCreateGroup || conservSostL1) && (
            <>
              {openCreateGroup && (
                <GroupGenerator open={openCreateGroup} setOpen={setOpenCreateGroup} handleSuccessful={handleOpenGroupCreationSuccessful} handleError={handleOpenGroupCreationError} />
              )}
              {openGroupCreationSuccessful && <GroupCreationSuccessfulSnackbar open={openGroupCreationSuccessful} setOpen={setOpenGroupCreationSuccessful} />}
              {openGroupCreationError && <GroupCreationErrorSnackbar open={openGroupCreationError} setOpen={setOpenGroupCreationError} />}
            </>
          )}

          {isMobile && (canCreateGroup || conservSostL1) && (
            <Fab color="primary" aria-label="add-group" sx={{ position: "fixed", bottom: 120, right: 16 }} onClick={handleOpenGroupGenerator}>
              <AddIcon />
            </Fab>
          )}
        </>
      )}
    </Grid>
  );
};

export default MyGroups;
