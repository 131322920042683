// Firebase
import { db } from "config/firebase";
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const addFCMToken = async (uid, fcm_token) => {
  try {
    const userRef = doc(db, "fcm_tokens", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      await setDoc(userRef, {
        fcm_tokens: [fcm_token],
      });
    } else {
      await updateDoc(userRef, {
        fcm_tokens: arrayUnion(fcm_token),
      });
    }
  } catch (error) {
    console.error("Error in addFCMToken:", error.message);
  }
};

export default addFCMToken;
