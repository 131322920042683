// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const enableForwardRoute = async (tagID, routeID) => {
  try {
    const routeRef = doc(db, "tagsdata", tagID, "forward_routes", routeID);

    await updateDoc(routeRef, {
      enabled: true,
      last_modified: unixTimestampInSeconds(),
    });
  } catch (error) {
    console.error(`Error in enableForwardRoute for tagID ${tagID} and routeID ${routeID}:`, error.message);
  }
};

export default enableForwardRoute;
