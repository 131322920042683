// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const isUserAuthorizedForDataBoxReadOnly = async (tagID, userID) => {
  try {
    const readOnlyPermissionRef = doc(db, `tagpermissions_readonly/${tagID}/authorized_users/${userID}`);
    const readOnlyPermissionSnap = await getDoc(readOnlyPermissionRef);

    return readOnlyPermissionSnap.exists();
  } catch (error) {
    console.error(`Error checking read-only authorization for databox [tagID: ${tagID}, userID: ${userID}]:`, error.message);
    return false;
  }
};

export default isUserAuthorizedForDataBoxReadOnly;
