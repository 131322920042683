// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// UserOperations
import addSignature from "UserOperations/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamImage = async (isPublic, userID, dataBody, dataCertification, timestamp, tdr) => {
  try {
    // Record transaction id (txid)
    const txid = dataCertification.txid;

    // Create new record in the recordsdata collection, using txid as document id
    const recordRef = doc(db, "recordsdata", txid);

    // Set the new record
    const recordData = {
      txid: txid,
      type: "img",
      version: 1,
      ...(tdr && { tdr: tdr }),
      creator_uuid: userID,
      data: {
        attachment: dataBody.downloadURL,
        fileName: dataBody.fileName,
        fileType: dataBody.fileType,
        caption: dataBody.caption,
        timestamp: timestamp,
      },
      fileName: dataBody.fileName,
      fileType: dataBody.fileType,
      timestamp: timestamp,
      public: isPublic,
    };

    console.log(recordData);

    await setDoc(recordRef, recordData);

    if (tdr) {
      // Create the record link to "tagsdata" collection, using txid as foreign key
      const tagRef = doc(db, "tagsdata", tdr);

      // Signatures collection reference related to the tag
      const recordsRef = collection(tagRef, "signatures");

      // Set the new record link to the "signatures" subcollection
      const signatureData = {
        txid: txid,
        type: "img",
        creator_uuid: userID,
        db_id: 1,
        fileName: dataBody.fileName,
        fileType: dataBody.fileType,
        timestamp: timestamp,
        public: isPublic,
      };

      console.log(signatureData);

      await setDoc(doc(recordsRef, txid), signatureData);
    }

    // Add the signature to "mysigs" subcollection of "userdata" collection
    tdr ? await addSignature(userID, txid, timestamp, "img", tdr) : await addSignature(userID, txid, timestamp, "img");

    const result = {
      txid: txid,
      success: true,
    };

    console.log(result);

    return result;
  } catch (error) {
    console.error("Error in sendDataStreamImage:", error.message);

    const result = {
      success: false,
      error: error.message,
    };

    return result;
  }
};

export default sendDataStreamImage;
