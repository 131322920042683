// sendCertificationStreamInfo
import sendCertificationStreamInfo from "./sendCertificationStreamInfo";

// sendDataStreamInfo
import sendDataStreamInfo from "./sendDataStreamInfo";

// Utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const genInfoRecord = async (isPublic, userID, keypair, dataBody, databoxID) => {
  try {
    const timestamp = unixTimestampInSeconds();

    if (databoxID) {
      // Certification stream
      const dataCertification = await sendCertificationStreamInfo(keypair, dataBody, timestamp, databoxID);

      if (!dataCertification.txid) {
        console.error("Error in genInfoRecord: missing txid");
        return {
          success: false,
          error: "Error in genInfoRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamInfo(isPublic, userID, dataBody, dataCertification, timestamp, databoxID);
      console.log(result);
      return result;
    } else {
      // Certification stream
      const dataCertification = await sendCertificationStreamInfo(keypair, dataBody, timestamp);

      if (!dataCertification.txid) {
        console.error("Error in genInfoRecord: missing txid");
        return {
          success: false,
          error: "Error in genInfoRecord: missing txid",
        };
      }

      // Data stream
      const result = await sendDataStreamInfo(isPublic, userID, dataBody, dataCertification, timestamp);
      console.log(result);
      return result;
    }
  } catch (error) {
    console.error("Error in genInfoRecord:", error.message);
    const result = {
      success: false,
      error: error.message,
    };
    console.log(result);
    return result;
  }
};

export default genInfoRecord;
