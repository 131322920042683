// React
import React, { useState, useEffect } from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { NewCertConfirm, NewRequestReject } from "ui-components/ORFeedbacks";

// SafeTwin
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";

// DocGenerator
import genDocRecord from "generator/DocGenerator/genDocRecord";

// TagOperations
import completeCertificationRequest from "TagOperations/completeCertificationRequest";

// Storage
import getFile from "storage/getFile";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocGeneratorFrame = ({ tag, open, setOpen, handleOpenCertificationRejected, handleOpenCertificationSuccessful, handleOpenCertificationError, request }) => {
  const { user } = UserAuth();

  const [keypair, setKeyPair] = useState(null);
  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [downloadURL, setDownloadUrl] = useState("");
  const [comment, setComment] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPublic, setIsPublic] = useState(false);
  const [reject, setReject] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) handleReset();
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    const downloadURL = request.data.downloadURL;

    const fetchFile = async () => {
      const bytearray = await getFile(downloadURL);
      setSource(bytearray);
    };

    fetchFile();

    setDownloadUrl(downloadURL);
    setComment(request.data.comment);
    setIsPublic(request.public);
  }, [request]);

  const handleReject = () => {
    console.error("Error Code 1005 - USER_REJECTED");
    handleOpenCertificationRejected();
    setReject(false);
    handleReset();
    window.parent.postMessage(
      {
        type: "QuickSignError",
        QuickSignError: { code: "1005", message: "USER_REJECTED" },
      },
      "*"
    );
  };

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        if (tag) {
          const QuickSignData = await genDocRecord(isPublic, user.uid, keypair, source, downloadURL, comment, tag);

          if (QuickSignData.txid) {
            await completeCertificationRequest(tag, request.id);
            console.log(QuickSignData);
            handleOpenCertificationSuccessful();
            window.parent.postMessage({ type: "QuickSignData", QuickSignData }, "*");
          } else {
            console.error("Error Code 1004 - CERT_STREAM_FAILED");
            handleOpenCertificationError();
            window.parent.postMessage(
              {
                type: "QuickSignError",
                QuickSignError: { code: "1004", message: "CERT_STREAM_FAILED" },
              },
              "*"
            );
          }
        }
      } catch (error) {
        console.error("Error Code 1004 - CERT_STREAM_FAILED");
        handleOpenCertificationError();
        window.parent.postMessage(
          {
            type: "QuickSignError",
            QuickSignError: { code: "1004", message: "CERT_STREAM_FAILED" },
          },
          "*"
        );
      }
    }

    setIsLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setDownloadUrl("");
    setComment("");
    setIsPublic(false);
    setOpen(false);
    const fileInput = document.getElementById("certify-doc-home-button");
    if (fileInput) fileInput.value = null;
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const DocReportForm = (
    <Box>
      <Grid item container justifyContent="center">
        <Button onClick={() => handleFileClick(downloadURL)} sx={{ mt: "1%", mb: "3%" }}>
          Open PDF File
        </Button>
      </Grid>
      <TextField
        margin="dense"
        id="Comments"
        label="Comments"
        name="Comments"
        variant="outlined"
        fullWidth
        value={comment}
        disabled
        onChange={(e) => setComment(e.target.value)}
        rows={3}
        multiline
        sx={{ mt: "1%", mb: "2%" }}
      />
      <FormGroup>
        <FormControlLabel control={<Switch checked={isPublic} disabled onChange={(event) => setIsPublic(event.target.checked)} inputProps={{ "aria-label": "controlled" }} />} label="Mark as public" />
      </FormGroup>
    </Box>
  );

  return (
    <>
      <Dialog open={open} onClose={handleReset} fullScreen>
        <Box sx={{ p: "2.5%" }}>
          <Grid container alignItems="center" justifyContent="space-between" mb="3%">
            <Grid item>
              <Typography variant="h5">Certify PDF File</Typography>
            </Grid>
          </Grid>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              {DocReportForm}
              <Grid container spacing={1} mt="1%">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" onClick={() => setReject(true)}>
                    Reject
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" disabled={isLoading || !source} onClick={() => setConfirm(true)}>
                    Certify
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Dialog>
      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestReject reject={reject} setReject={setReject} handleReject={handleReject} />
      {selectedFile && (
        <Dialog open={Boolean(selectedFile)} onClose={handleCloseDialog}>
          <IconButton
            sx={{
              color: "red",
              justifyContent: "flex-end",
            }}
            onClick={handleCloseDialog}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
            <Page pageNumber={currentPage} width="600" renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
              <KeyboardArrowLeftOutlinedIcon />
            </IconButton>
            <Typography sx={{ mx: 2 }}>
              {currentPage} / {numPages}
            </Typography>
            <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages}>
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default DocGeneratorFrame;
