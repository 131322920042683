// React
import React, { useState, useEffect } from "react";

// Material UI Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

// Material UI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";

// TagOperations
import fetchDataboxEvents from "TagOperations/fetchDataboxEvents";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const DataboxLogDialog = ({ isOpen, onClose, databoxID }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (isOpen && databoxID) {
      const loadEvents = async () => {
        const fetchedEvents = await fetchDataboxEvents(databoxID);
        setEvents(fetchedEvents);
      };
      loadEvents();
    }
  }, [isOpen, databoxID]);

  // Icon mapping for action types
  const getIcon = (type) => {
    switch (type) {
      case "certification":
        return <CheckCircleIcon sx={{ color: "#2196f3" }} />;
      case "verification":
        return <VerifiedIcon sx={{ color: "#4caf50" }} />;
      case "error":
        return <ErrorIcon sx={{ color: "#f44336" }} />;
      default:
        return <CheckCircleIcon />;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "rgba(38, 50, 56, 0.95)",
          color: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle sx={{ color: "#4caf50", fontWeight: "bold" }}>Databox Event Log</DialogTitle>
      <DialogContent>
        <Divider sx={{ bgcolor: "#4caf50", mb: 2 }} />
        <Timeline position="alternate">
          {events.map((event, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot>{getIcon(event.eventType)}</TimelineDot>
                {index < events.length - 1 && <TimelineConnector sx={{ bgcolor: "#4caf50" }} />}
              </TimelineSeparator>
              <TimelineContent>
                {event.eventType && (
                  <Typography variant="body2" fontWeight="bold">
                    {event.eventType.toUpperCase()}
                  </Typography>
                )}
                {event.timestamp && (
                  <Typography variant="body2" fontWeight="bold">
                    {convertTimestamp(event.timestamp)}
                  </Typography>
                )}
                <Grid container>
                  {event.description && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        {event.description}
                      </Typography>
                    </Grid>
                  )}
                  {event.metadata?.certificationID && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Certification ID: {event.metadata?.certificationID}
                      </Typography>
                    </Grid>
                  )}
                  {event.performedBy && (
                    <Grid item xs={12}>
                      <Typography variant="caption" sx={{ color: "#b0bec5" }}>
                        Performed By: {event.performedBy}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </DialogContent>
    </Dialog>
  );
};

export default DataboxLogDialog;
